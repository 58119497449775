import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faUsers, faBookOpen } from '@fortawesome/free-solid-svg-icons'
import { DUMMY_PROFILE_IMAGE } from '../../../utils/constants'

const InstructorCard = ({ instructor }) => {
  return (
    <div className="bg-white rounded-lg shadow-sm hover:shadow-md transition-all duration-300">
      <div className="p-6">
        <div className="flex items-center gap-4">
          <img
            src={instructor.image || DUMMY_PROFILE_IMAGE}
            alt={instructor.name}
            className="w-20 h-20 rounded-full object-cover"
          />
          <div>
            <h3 className="font-semibold text-lg">{instructor.name}</h3>
            <p className="text-gray-600 text-sm">{instructor.role}</p>
            <p className="text-primary text-sm">{instructor.specialization}</p>
          </div>
        </div>

        <div className="mt-6 grid grid-cols-3 gap-4 text-center">
          <StatisticItem
            icon={faStar}
            value={instructor.rating}
            label="Rating"
            colorClass="text-yellow-500"
          />
          <StatisticItem
            icon={faUsers}
            value={`${(instructor.students / 1000).toFixed(1)}k`}
            label="Students"
            colorClass="text-primary"
          />
          <StatisticItem
            icon={faBookOpen}
            value={instructor.courses}
            label="Courses"
            colorClass="text-green-600"
          />
        </div>

        <div className="mt-6 flex justify-center space-x-4">
          {Object.entries(instructor.social).map(([platform, link]) => (
            <SocialLink key={platform} platform={platform} link={link} />
          ))}
        </div>

        <button className="w-full mt-6 px-6 py-2 bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors">
          View Profile
        </button>
      </div>
    </div>
  )
}

const StatisticItem = ({ icon, value, label, colorClass }) => (
  <div>
    <div className={`flex items-center justify-center gap-1 ${colorClass}`}>
      <FontAwesomeIcon icon={icon} />
      <span className="font-semibold">{value}</span>
    </div>
    <p className="text-xs text-gray-600 mt-1">{label}</p>
  </div>
)

const SocialLink = ({ platform, link }) => (
  <a
    href={link}
    className="text-gray-400 hover:text-primary transition-colors duration-300"
  >
    <i className={`fab fa-${platform} text-xl`}></i>
  </a>
)

export default InstructorCard
