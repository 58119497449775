import React, { useState, useCallback, useEffect, useMemo } from 'react'
import BreadCrumb from '../../BreadCrumb'
import { Search } from 'react-feather'
import { debounce } from 'lodash'
import courseImage from '../../../assets/images/library/Courses.png'

const Resources = function () {
  const [searchQuery, setSearchQuery] = useState('')
  const [isSearching, setIsSearching] = useState(false)

  // Filter states
  const [filters, setFilters] = useState({
    classType: [],
    category: [],
    subject: [],
  })

  // Add new states
  const [selectedResources, setSelectedResources] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const resourcesPerPage = 10
  const [filteredResources, setFilteredResources] = useState([])

  // Memoize the resources array
  const resources = useMemo(
    () => [
      {
        id: 1,
        title: 'Advanced Mathematics',
        duration: '48 Hours',
        instructor: 'Dr. Sarah Chen',
        studentsEnrolled: '+ 250',
        image: courseImage,
        subject: 'Mathematics',
        classType: 'Upper Sixth',
        category: 'Video',
        level: 'Advanced',
      },
      {
        id: 2,
        title: 'Chemistry Fundamentals',
        duration: '36 Hours',
        instructor: 'Prof. Michael Brown',
        studentsEnrolled: '+ 180',
        image: courseImage,
        subject: 'Chemistry',
        classType: 'Form 5',
        category: 'PDF',
        level: 'Ordinary',
      },
      {
        id: 3,
        title: 'Biology and Life Sciences',
        duration: '42 Hours',
        instructor: 'Dr. Emily Watson',
        studentsEnrolled: '+ 200',
        image: courseImage,
        subject: 'Biology',
        classType: 'Lower Sixth',
        category: 'Video',
        level: 'Advanced',
      },
      {
        id: 4,
        title: 'World Literature',
        duration: '30 Hours',
        instructor: 'Prof. James Wilson',
        studentsEnrolled: '+ 150',
        image: courseImage,
        subject: 'Literature',
        classType: 'Form 4',
        category: 'PDF',
        level: 'Ordinary',
      },
      {
        id: 5,
        title: 'Modern History',
        duration: '38 Hours',
        instructor: 'Dr. Eleanor Pena',
        studentsEnrolled: '+ 175',
        image: courseImage,
        subject: 'History',
        classType: 'Form 3',
        category: 'Video',
        level: 'Advanced',
      },
      {
        id: 6,
        title: 'English Language Skills',
        duration: '45 Hours',
        instructor: 'Sarah Thompson',
        studentsEnrolled: '+ 300',
        image: courseImage,
        subject: 'English Language',
        classType: 'Form 2',
        category: 'Video',
        level: 'Advanced',
      },
      {
        id: 7,
        title: 'Physics Mastery',
        duration: '50 Hours',
        instructor: 'Dr. Robert Chen',
        studentsEnrolled: '+ 160',
        image: courseImage,
        subject: 'Physics',
        classType: 'Form 1',
        category: 'Video',
        level: 'Advanced',
      },
      {
        id: 8,
        title: 'Food Science',
        duration: '32 Hours',
        instructor: 'Chef Maria Garcia',
        studentsEnrolled: '+ 120',
        image: courseImage,
        subject: 'Food & Nutrition',
        classType: 'Form 5',
        category: 'PDF',
        level: 'Ordinary',
      },
      {
        id: 9,
        title: 'Advanced Chemistry Lab',
        duration: '40 Hours',
        instructor: 'Dr. David Lee',
        studentsEnrolled: '+ 90',
        image: courseImage,
        subject: 'Chemistry',
        classType: 'Form 5',
        category: 'Video',
        level: 'Advanced',
      },
      {
        id: 10,
        title: 'Literature Analysis',
        duration: '35 Hours',
        instructor: 'Prof. Amanda White',
        studentsEnrolled: '+ 140',
        image: courseImage,
        subject: 'Literature',
        classType: 'Form 4',
        category: 'PDF',
        level: 'Ordinary',
      },
      {
        id: 11,
        title: 'Mathematical Problem Solving',
        duration: '44 Hours',
        instructor: 'Dr. John Martinez',
        studentsEnrolled: '+ 220',
        image: courseImage,
        subject: 'Mathematics',
        classType: 'Form 3',
        category: 'Video',
        level: 'Advanced',
      },
      {
        id: 12,
        title: 'Biology Research Methods',
        duration: '38 Hours',
        instructor: 'Prof. Lisa Anderson',
        studentsEnrolled: '+ 110',
        image: courseImage,
        subject: 'Biology',
        classType: 'Form 2',
        category: 'PDF',
        level: 'Ordinary',
      },
    ],
    []
  )

  const handleFilterChange = (category, value) => {
    setFilters((prev) => ({
      ...prev,
      [category]: prev[category].includes(value)
        ? prev[category].filter((item) => item !== value)
        : [...prev[category], value],
    }))
  }

  // Define the debounced function outside of useCallback
  const debouncedSearch = debounce((query) => {
    setIsSearching(true)
    const searchResults = resources.filter(
      (resource) =>
        resource.title.toLowerCase().includes(query.toLowerCase()) ||
        resource.instructor.toLowerCase().includes(query.toLowerCase())
    )
    setFilteredResources(searchResults)
    setIsSearching(false)
  }, 500)

  // Use useCallback to wrap the debounced function
  const handleSearch = useCallback(
    (query) => {
      debouncedSearch(query)
    },
    [debouncedSearch]
  )

  useEffect(() => {
    if (!searchQuery) {
      const filtered = resources.filter((resource) => {
        const matchesClassType =
          filters.classType.length === 0 ||
          filters.classType.includes(resource.classType)

        const matchesCategory =
          filters.category.length === 0 ||
          filters.category.includes(resource.category)

        const matchesSubject =
          filters.subject.length === 0 ||
          filters.subject.includes(resource.subject)

        return matchesClassType && matchesCategory && matchesSubject
      })

      setFilteredResources(filtered)
    }
  }, [filters, resources, searchQuery])

  const indexOfLastResource = currentPage * resourcesPerPage
  const indexOfFirstResource = indexOfLastResource - resourcesPerPage
  const currentResources = filteredResources.slice(
    indexOfFirstResource,
    indexOfLastResource
  )

  // Add a function to toggle resource selection
  const toggleResourceSelection = (id) => {
    setSelectedResources((prev) =>
      prev.includes(id) ? prev.filter((i) => i !== id) : [...prev, id]
    )
  }

  return (
    <main className="p-4 md:p-8">
      <BreadCrumb
        title={'My Resources'}
        slugs={[
          { display: 'Dashboard', to: '/dashboard/home', active: false },
          { display: 'Resources', to: '/dashboard/resources', active: true },
        ]}
      />

      <div className="mt-8">
        {/* Add Search Bar */}
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 mb-6">
          <h1 className="text-2xl font-bold text-blue-900">
            Browse Resources to keep yourself on the track
          </h1>
          <div className="relative w-full md:w-96">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value)
                handleSearch(e.target.value)
              }}
              placeholder="Search resources..."
              className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
            />
            {isSearching ? (
              <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                <div className="animate-spin h-5 w-5 border-2 border-blue-500 border-t-transparent rounded-full" />
              </div>
            ) : (
              <Search
                className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                size={20}
              />
            )}
          </div>
        </div>

        {/* Conditionally render filters only when searchQuery is empty */}
        {!searchQuery && (
          <div className="bg-white rounded-lg shadow p-6 mb-8">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-lg font-semibold">Filters</h2>
              <button
                onClick={() =>
                  setFilters({ classType: [], category: [], subject: [] })
                }
                className="text-sm text-blue-600 hover:text-blue-800"
              >
                Clear all filters
              </button>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {/* Class Types */}
              <div className="flex-1">
                <label
                  htmlFor="classType"
                  className="block text-sm font-medium mb-2"
                >
                  Class Types
                </label>
                <select
                  id="classType"
                  className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={filters.classType}
                  onChange={(e) =>
                    handleFilterChange('classType', e.target.value)
                  }
                >
                  <option value="">All Class Types</option>
                  {[
                    'Upper Sixth',
                    'Lower Sixth',
                    'Form 5',
                    'Form 4',
                    'Form 3',
                    'Form 2',
                    'Form 1',
                  ].map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </div>

              {/* Category */}
              <div className="flex-1">
                <label
                  htmlFor="category"
                  className="block text-sm font-medium mb-2"
                >
                  Category
                </label>
                <select
                  id="category"
                  className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={filters.category}
                  onChange={(e) =>
                    handleFilterChange('category', e.target.value)
                  }
                >
                  <option value="">All Categories</option>
                  {['PDF', 'Video'].map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </div>

              {/* Subject */}
              <div className="flex-1">
                <label
                  htmlFor="subject"
                  className="block text-sm font-medium mb-2"
                >
                  Subject
                </label>
                <select
                  id="subject"
                  className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={filters.subject}
                  onChange={(e) =>
                    handleFilterChange('subject', e.target.value)
                  }
                >
                  <option value="">All Subjects</option>
                  {[
                    'Mathematics',
                    'Chemistry',
                    'Biology',
                    'Literature',
                    'History',
                    'English Language',
                    'Physics',
                    'Food & Nutrition',
                  ].map((subject) => (
                    <option key={subject} value={subject}>
                      {subject}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}

        {/* Update Resources Table */}
        <div className="bg-white rounded-lg shadow overflow-hidden">
          {isSearching ? (
            <div className="p-12 text-center">
              <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-blue-900 mx-auto"></div>
              <p className="mt-4 text-gray-600 text-lg">
                Loading your resources...
              </p>
            </div>
          ) : resources.length === 0 ? (
            <div className="p-12 text-center">
              <div className="mx-auto w-24 h-24 bg-gray-100 rounded-full flex items-center justify-center mb-4">
                <Search className="text-gray-400" size={32} />
              </div>
              <p className="text-gray-600 text-lg">
                No resources found matching your criteria.
              </p>
              <button
                onClick={() =>
                  setFilters({ classType: [], category: [], subject: [] })
                }
                className="mt-4 text-blue-600 hover:text-blue-800"
              >
                Clear all filters
              </button>
            </div>
          ) : (
            <>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {currentResources.map((resource) => (
                  <div
                    key={resource.id}
                    className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow"
                  >
                    <div className="relative pb-48">
                      <img
                        src={resource.image}
                        alt={resource.title}
                        className="absolute h-full w-full object-cover rounded-t-lg"
                      />
                    </div>
                    <div className="p-4">
                      <div className="flex justify-between items-center mb-2">
                        <h3 className="text-lg font-semibold">
                          {resource.title}
                        </h3>
                        <input
                          type="checkbox"
                          checked={selectedResources.includes(resource.id)}
                          onChange={() => toggleResourceSelection(resource.id)}
                        />
                      </div>
                      <div className="flex items-center text-sm text-gray-600 mb-4">
                        <span className="mr-4">{resource.duration}</span>
                        <span>{resource.instructor}</span>
                      </div>
                      <div className="flex justify-between items-center">
                        <span className="text-sm text-gray-500">
                          {resource.studentsEnrolled} students
                        </span>
                        <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors">
                          Enroll Now
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* Add Pagination */}
              <div className="px-6 py-4 flex items-center justify-between border-t border-gray-200">
                <div className="text-sm text-gray-700">
                  Showing {(currentPage - 1) * resourcesPerPage + 1} to{' '}
                  {Math.min(currentPage * resourcesPerPage, resources.length)}{' '}
                  of {resources.length} results
                </div>
                <div className="flex gap-2">
                  <button
                    onClick={() => setCurrentPage((p) => Math.max(1, p - 1))}
                    disabled={currentPage === 1}
                    className="px-4 py-2 border rounded-lg disabled:opacity-50"
                  >
                    Previous
                  </button>
                  <button
                    onClick={() => setCurrentPage((p) => p + 1)}
                    disabled={
                      currentPage * resourcesPerPage >= resources.length
                    }
                    className="px-4 py-2 border rounded-lg disabled:opacity-50"
                  >
                    Next
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </main>
  )
}

export default Resources
