import React from 'react'
import BreadCrumb from '../../BreadCrumb'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBell,
  faSearch,
  faPlus,
  faClock,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons'
import courseImage from '../../../assets/images/library/Courses.png'


const Courses = function () {
  // Mock data for assignments
  const assignments = [
    {
      title: 'History Assignment - Find out how the wheel was invented.',
      course: 'History Course',
      dueDate: 'Oct 28',
      icon: faBell,
    },
    {
      title: 'Exam',
      course: 'History Course',
      dueDate: 'Oct 28',
      icon: faBell,
    },
  ]

  // Mock data for in-progress courses
  const inProgressCourses = [
    {
      id: 1,
      title: 'History Course',
      hours: '12 Hours',
      status: 'Watched',
      progress: 65,
      students: '16 Students',
      enrollmentStatus: 'Enrolled',
      startDate: '10 Oct 2023',
      finishDate: '10 Dec 2023',
      description:
        'Gain valuable insights, broaden your historical perspective, and explore the narratives that define our shared heritage.',
      image: courseImage,
    },
    {
      id: 2,
      title: 'History Course',
      hours: '43 Hours',
      status: 'Watched',
      progress: 65,
      students: '16 Students',
      enrollmentStatus: 'Enrolled',
      startDate: '10 Oct 2023',
      finishDate: '10 Dec 2023',
      description:
        'Gain valuable insights, broaden your historical perspective, and explore the narratives that define our shared heritage.',
      image: courseImage,
    },
  ]

  return (
    <main className="flex-1 w-full">
      <BreadCrumb
        key={Math.random() * 1000}
        title={'My Courses'}
        slugs={[
          {
            display: 'Dashboard',
            to: '/dashboard/home',
            active: false,
          },
          {
            display: 'Courses',
            to: '/dashboard/courses',
            active: true,
          },
        ]}
      ></BreadCrumb>

      <section className="py-6 px-4">
        <div className="max-w-7xl mx-auto">
          {/* Upcoming Assignments Section */}
          <div className="mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              Upcoming Assignments
            </h2>
            <div className="bg-[#1f4690] rounded-xl p-4 space-y-2">
              {assignments.map((assignment, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg p-4 flex justify-between items-center hover:shadow-lg transform hover:-translate-y-1 transition-all duration-200"
                >
                  <div className="flex items-center gap-4">
                    <div
                      className={`p-3 rounded-lg ${
                        assignment.status === 'pending'
                          ? 'bg-yellow-500'
                          : 'bg-[#1f4690]'
                      }`}
                    >
                      <FontAwesomeIcon
                        icon={
                          assignment.status === 'pending'
                            ? faClock
                            : faCheckCircle
                        }
                        className="text-white"
                      />
                    </div>
                    <div>
                      <h3 className="font-medium text-gray-800">
                        {assignment.title}
                      </h3>
                      <p className="text-sm text-gray-600">
                        {assignment.course}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <div className="text-right">
                      <span className="text-sm text-gray-600">Due Date:</span>
                      <span className="text-sm text-[#1f4690] ml-2">
                        {assignment.dueDate}
                      </span>
                    </div>
                    <button className="bg-[#1f4690] text-white px-4 py-2 rounded-lg hover:bg-[#1a3a7a] transition-colors">
                      Submit
                    </button>
                  </div>
                </div>
              ))}
              <div className="flex justify-end mt-2">
                <button className="text-white hover:text-gray-200 transition-colors text-sm font-medium px-4">
                  See all
                </button>
              </div>
            </div>
          </div>

          {/* Main Content Grid */}
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
            {/* In Progress Courses Section - Takes up 3 columns */}
            <div className="lg:col-span-3">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-800">
                  In Progress Courses
                </h2>
                <button className="flex items-center gap-2 text-[#1f4690] hover:text-[#1a3a7a] transition-colors">
                  <span>Discover New Courses</span>
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {inProgressCourses.map((course) => (
                  <div
                    key={course.id}
                    className="bg-white rounded-lg shadow-sm overflow-hidden hover:shadow-md transition-all duration-200"
                  >
                    <div className="relative">
                      <img
                        src={course.image}
                        alt={course.title}
                        className="w-full h-40 object-cover transform hover:scale-105 transition-transform duration-200"
                      />
                      <div className="absolute top-2 right-2 bg-white px-2 py-1 rounded-full text-xs font-medium text-[#1f4690]">
                        {course.enrollmentStatus}
                      </div>
                    </div>
                    <div className="p-4">
                      <h3 className="font-medium text-gray-800 mb-3 text-lg">
                        {course.title}
                      </h3>

                      <div className="mb-3">
                        <div className="flex justify-between text-sm mb-1">
                          <span className="text-gray-600">Progress</span>
                          <span className="text-[#1f4690] font-medium">
                            {course.progress}%
                          </span>
                        </div>
                        <div className="w-full bg-gray-200 rounded-full h-2">
                          <div
                            className="bg-[#1f4690] h-2 rounded-full transition-all duration-300"
                            style={{ width: `${course.progress}%` }}
                          ></div>
                        </div>
                      </div>

                      <div className="space-y-2 text-sm">
                        <p className="text-gray-600">
                          {course.hours} {course.status}
                        </p>
                        <p className="text-gray-600">
                          {course.students} {course.enrollmentStatus}
                        </p>
                        <div className="flex justify-between text-xs pt-1">
                          <div>
                            <span className="text-gray-600">Start: </span>
                            <span className="text-green-500">
                              {course.startDate}
                            </span>
                          </div>
                          <div>
                            <span className="text-gray-600">End: </span>
                            <span className="text-[#1f4690]">
                              {course.finishDate}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="flex gap-2 mt-4">
                        <button className="text-gray-600 border border-gray-300 px-4 py-2 rounded-lg hover:bg-gray-50 transition-colors text-sm flex-1 flex items-center justify-center gap-2">
                          <FontAwesomeIcon
                            icon={faBell}
                            className="text-gray-400"
                          />
                          Assignments
                        </button>
                        <button className="bg-[#1f4690] text-white px-4 py-2 rounded-lg hover:bg-[#1a3a7a] transition-colors text-sm flex-1 flex items-center justify-center gap-2">
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Drafts Section - Takes up 1 column */}
            <div className="lg:col-span-1">
              <h2 className="text-xl font-semibold text-gray-800 mb-6">
                Drafts
              </h2>
              <div className="bg-white rounded-lg shadow-sm p-6 flex flex-col items-center justify-center min-h-[200px]">
                <div className="text-6xl text-[#1f4690] mb-4">
                  <FontAwesomeIcon icon={faSearch} />
                </div>
                <p className="text-gray-600 text-lg">No Drafts</p>
              </div>
            </div>
          </div>

          {/* Rest of your courses content will go here */}
        </div>
      </section>
    </main>
  )
}

export default Courses
