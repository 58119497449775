import React from 'react'
import Banner from '../../../components/Banner'
import Footer from '../../../components/Footer'

const TermsOfService = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <Banner
        title="Terms of Service"
        content="Please read these terms carefully before using our educational platform."
      />

      <div className="container mx-auto px-4 py-12">
        <div className="bg-white rounded-lg shadow-sm p-8 space-y-8">
          <section>
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              1. Acceptance of Terms
            </h2>
            <p className="text-gray-600 leading-relaxed">
              By accessing and using KET Academy's platform and services, you
              agree to be bound by these Terms of Service. These terms apply to
              all users, including students, educators, and visitors. Your use
              of our platform indicates your understanding and acceptance of
              these terms.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              2. Educational Services
            </h2>
            <p className="text-gray-600 leading-relaxed mb-4">
              KET Academy provides educational content, resources, and
              AI-powered learning tools. While we strive to maintain high
              standards of quality and accuracy, we make no guarantees about the
              completeness or absolute accuracy of our content.
            </p>
            <ul className="list-disc pl-6 text-gray-600 space-y-2">
              <li>
                Course materials and resources are for personal educational use
                only
              </li>
              <li>
                Access to content may vary based on your subscription/signup level where/when applicable
              </li>
              <li>
                Certificates and achievements are subject to completion
                requirements
              </li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              3. AI-Powered Features
            </h2>
            <p className="text-gray-600 leading-relaxed mb-4">
              Our platform incorporates AI technology to enhance your learning
              experience. By using these features, you acknowledge and agree to
              the following:
            </p>
            <ul className="list-disc pl-6 text-gray-600 space-y-2">
              <li>
                AI responses are generated automatically and may not always be
                100% accurate
              </li>
              <li>
                AI tools should be used as learning aids, not as definitive
                sources
              </li>
              <li>
                Users should verify important information from multiple sources
              </li>
              <li>
                We are not liable for decisions made based on AI-generated
                content
              </li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              4. User Accounts and Security
            </h2>
            <p className="text-gray-600 leading-relaxed">
              Users are responsible for maintaining the confidentiality of their
              account credentials and for all activities under their account.
              You must:
            </p>
            <ul className="list-disc pl-6 text-gray-600 space-y-2 mt-4">
              <li>Provide accurate and complete registration information</li>
              <li>Update your information to keep it current</li>
              <li>Notify us immediately of any unauthorized access</li>
              <li>Not share your account credentials with others</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              5. Intellectual Property
            </h2>
            <p className="text-gray-600 leading-relaxed">
              All content on KET Academy, including courses, materials, and
              AI-generated content, is protected by intellectual property
              rights. Users may not:
            </p>
            <ul className="list-disc pl-6 text-gray-600 space-y-2 mt-4">
              <li>Copy or distribute platform content without permission</li>
              <li>Use content for commercial purposes</li>
              <li>Modify or create derivative works</li>
              <li>Attempt to reverse engineer our systems or AI tools</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              6. Privacy and Data Usage
            </h2>
            <p className="text-gray-600 leading-relaxed">
              Your use of KET Academy is also governed by our Privacy Policy. We
              collect and process data, including your interactions with our AI
              tools, to improve your learning experience. Please review our
              Privacy Policy for complete details on data collection and usage
              practices.
            </p>
          </section>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default TermsOfService
