// Import FontAwesome icons for UI elements
import {
  faCamera,
  faExclamationTriangle,
  faMinusCircle,
  faPlus,
  faSync,
  faTrashAlt,
  faUserEdit,
  faUserLock,
  faUserXmark,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Import React hooks and utilities
import React, { useEffect, useState } from 'react'
import { concentrations, DUMMY_PROFILE_IMAGE } from '../../../utils/constants'
import BreadCrumb from '../../BreadCrumb'
//import './styles.scss'

// Import dummy profile image
//import dummy from '../../../assets/images/people/Kinlo.jpg'

/**
 * Profile Component
 * Handles user profile management including personal info, academic details and account settings
 */
const Profile = function () {
  // State management for user profile data
  const [user, setUser] = useState(null) // Main user object
  //const [bio, setBio] = useState('') // User biography
  const [region, setRegion] = useState('') // User's region
  const [city, setCity] = useState('') // User's city
  const [edSystem, setEdSystem] = useState('') // Educational system (French/English)
  const [specialty, setSpecialty] = useState('') // Academic specialty
  const [level, setLevel] = useState('') // Academic level
  const [school, setSchool] = useState('') // User's school
  const [subjects, setSubjects] = useState([]) // Array of subjects
  const [profile, setProfile] = useState({
    fullName: '',
    email: '',
    bio: '',
    profileImage: null,
    // ... other profile fields
  })

  // TODO: Implement side effects (e.g., fetch user data on component mount)
  useEffect(() => {})

  // Handle image upload
  const handleImageUpload = async (e) => {
    const file = e.target.files?.[0]
    if (!file) return

    try {
      // Create a preview URL for immediate display
      const previewUrl = URL.createObjectURL(file)
      setProfile((prev) => ({ ...prev, profileImage: previewUrl }))

      // TODO: Implement actual image upload to server
      const formData = new FormData()
      formData.append('image', file)

      // const response = await fetch('/api/upload-image', {
      //   method: 'POST',
      //   body: formData,
      // })
      // const { imageUrl } = await response.json()
      // setProfile(prev => ({ ...prev, profileImage: imageUrl }))
    } catch (error) {
      console.error('Error uploading image:', error)
      // Optionally show error message to user
    }
  }

  return (
    <main className="w-full p-4 md:p-8">
      <BreadCrumb
        title="Profile"
        slugs={[
          { display: 'Dashboard', to: '/dashboard/home', active: false },
          { display: 'Profile', to: '/dashboard/profile', active: true },
        ]}
      />

      <section className="w-full max-w-[1200px] mx-auto my-8 p-8 md:p-12 bg-white rounded-xl shadow-md">
        <div className="container">
          {/* Personal Profile Section */}
          <div className="w-full py-6">
            <div className="mb-1">
              <h2 className="text-2xl mb-0.1 text-primary/100">
                Your Personal Profile
              </h2>
              <p className="text-sm leading-6">
                Your profile contains your basic information
              </p>
            </div>

            <div className="flex flex-wrap gap-12 p-6 bg-secondary/[0.02] rounded-lg">
              {/* Profile Picture Management */}
              <div className="flex-none">
                <h4 className="font-primary text-base font-extrabold text-neutral-800 pb-1">
                  Profile Picture
                </h4>
                <div className="relative">
                  <img
                    src={profile.profileImage || DUMMY_PROFILE_IMAGE}
                    alt="Profile"
                    className="w-[220px] h-[220px] rounded-full object-cover border-4 border-white shadow-md"
                  />
                  {/* Camera Button - Top Right */}
                  <label className="absolute top-2 right-2 cursor-pointer p-3 bg-white rounded-full shadow-md transition-transform duration-300 hover:scale-110">
                    <input
                      type="file"
                      className="hidden"
                      accept="image/*"
                      onChange={handleImageUpload}
                    />
                    <FontAwesomeIcon
                      icon={faCamera}
                      className="text-primary hover:text-primary/80 text-lg"
                    />
                  </label>
                  {/* Delete Button - Bottom Right - Only show if there's a custom profile image */}
                  {profile.profileImage && (
                    <button
                      className="absolute bottom-2 right-2 p-3 bg-white rounded-full shadow-md transition-transform duration-300 hover:scale-110"
                      onClick={() =>
                        setProfile((prev) => ({ ...prev, profileImage: null }))
                      }
                    >
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        className="text-error hover:text-error/80 text-lg"
                      />
                    </button>
                  )}
                </div>
              </div>

              {/* Biography Section */}
              <div className="flex-1 w-full">
                <h4 className="font-primary text-base font-extrabold text-neutral-800 pb-2">
                  Personal Bio
                </h4>
                <textarea
                  className="w-full min-h-[180px] p-4 leading-6 resize-y rounded border border-primary focus:ring-2 focus:ring-primary/30"
                  value={profile.bio}
                  onChange={(e) =>
                    setProfile((prev) => ({ ...prev, bio: e.target.value }))
                  }
                  placeholder="Tell us about yourself..."
                />
              </div>
            </div>

            {/* Profile Update Button */}
            <button className="mt-4 px-6 py-3 bg-primary text-white rounded-lg font-medium tracking-wide transition-all duration-300 hover:-translate-y-1 hover:shadow-lg hover:shadow-primary/30">
              <FontAwesomeIcon icon={faUserEdit} className="mr-2" />
              Update Profile
            </button>
          </div>

          {/* Student Profile Section */}
          <div className="w-full py-6">
            <div className="mb-8">
              <h2 className="text-2xl mb-1 py-0 text-primary/90">
                Your KET Academy <strong>Student</strong> Profile
              </h2>
              <p className="text-sm leading-6">
                Your personal student information for a personalised experience.
              </p>
            </div>

            <div className="space-y-8">
              {/* KET ID Card Section */}
              <div>
                <h4 className="font-primary text-base font-extrabold text-neutral-800 py-4">
                  Your KET ID card
                </h4>
                <div
                  className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 my-4"
                  role="alert"
                >
                  <p className="font-bold">Incomplete profile</p>
                  <p>
                    Your ID Card will be created after you verify your email and
                    complete setting up this profile page
                  </p>
                </div>
              </div>

              {/* Form Fields */}
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {/* Name Fields */}
                <div>
                  <label className="block text-base mb-1">Full Name</label>
                  <input
                    type="text"
                    className="w-full max-w-[300px] p-2 border border-primary rounded-md focus:ring-2 focus:ring-neutral-300"
                    value={user?.fullName || ''}
                    onChange={(e) =>
                      setUser({ ...user, fullName: e.target.value })
                    }
                    placeholder="Enter your name"
                  />
                </div>

                {/* Region Field */}
                <div>
                  <label className="block text-base mb-1">Region</label>
                  <input
                    type="text"
                    className="w-full max-w-[300px] p-2 border border-primary rounded-md focus:ring-2 focus:ring-neutral-300"
                    value={region}
                    onChange={(e) => setRegion(e.target.value)}
                    placeholder="Enter your region"
                  />
                </div>

                {/* City Field */}
                <div>
                  <label className="block text-base mb-1">
                    City of studies
                  </label>
                  <input
                    type="text"
                    className="w-full max-w-[300px] p-2 border border-primary rounded-md focus:ring-2 focus:ring-neutral-300"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    placeholder="Enter your city"
                  />
                </div>

                {/* Education System Field */}
                <div>
                  <label className="block text-base mb-1">
                    Education System
                  </label>
                  <select
                    className="w-full max-w-[300px] p-2 border border-primary rounded-md focus:ring-2 focus:ring-neutral-300"
                    value={edSystem}
                    onChange={(e) => setEdSystem(e.target.value)}
                  >
                    <option value="">Select System</option>
                    <option value="french">French</option>
                    <option value="english">English</option>
                  </select>
                </div>

                {/* Specialty Field */}
                <div>
                  <label className="block text-base mb-1">Specialty</label>
                  <select
                    className="w-full max-w-[300px] p-2 border border-primary rounded-md focus:ring-2 focus:ring-neutral-300"
                    value={specialty}
                    onChange={(e) => setSpecialty(e.target.value)}
                  >
                    <option value="">Select Specialty</option>
                    {concentrations.map((concentration, index) => (
                      <option key={index} value={concentration.toLowerCase()}>
                        {concentration}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Academic Level Field */}
                <div>
                  <label className="block text-base mb-1">Academic Level</label>
                  <select
                    className="w-full max-w-[300px] p-2 border border-primary rounded-md focus:ring-2 focus:ring-neutral-300"
                    value={level}
                    onChange={(e) => setLevel(e.target.value)}
                  >
                    <option value="">Select Level</option>
                    <option value="form1">Form 1</option>
                    <option value="form2">Form 2</option>
                    <option value="form3">Form 3</option>
                    <option value="form4">Form 4</option>
                    <option value="form5">Form 5</option>
                    <option value="lower6">Lower 6</option>
                    <option value="upper6">Upper 6</option>
                  </select>
                </div>

                {/* School Field */}
                <div>
                  <label className="block text-base mb-1">School</label>
                  <input
                    type="text"
                    className="w-full max-w-[300px] p-2 border border-primary rounded-md focus:ring-2 focus:ring-neutral-300"
                    value={school}
                    onChange={(e) => setSchool(e.target.value)}
                    placeholder="Enter your school name"
                  />
                </div>
              </div>

              {/* Save Changes Button */}
              <button className="mt-8 px-6 py-2 bg-primary text-white rounded-md transition-all duration-300 hover:-translate-y-1 hover:shadow-lg">
                <FontAwesomeIcon icon={faSync} className="mr-2" />
                Save Changes
              </button>

              {/* Subjects Section */}
              <div className="space-y-4">
                <h4 className="font-primary text-base font-extrabold text-neutral-800">
                  Your Subjects
                </h4>
                <div className="flex flex-wrap gap-3">
                  {subjects.map((subject, index) => (
                    <span
                      key={index}
                      className="flex items-center px-3 py-1 bg-white border border-primary rounded-md"
                    >
                      {subject}
                      <button
                        onClick={() =>
                          setSubjects(subjects.filter((sub) => sub !== subject))
                        }
                        className="ml-2 text-error hover:text-error-dark"
                      >
                        <FontAwesomeIcon icon={faMinusCircle} />
                      </button>
                    </span>
                  ))}
                </div>
                <button
                  onClick={() => setSubjects([...subjects, 'New Subject'])}
                  className="px-8 py-2 bg-primary text-secondary font-normal rounded-md transition-all duration-300 hover:-translate-y-1 hover:shadow-lg"
                >
                  <FontAwesomeIcon icon={faPlus} />
                  Add Subject
                </button>
              </div>
            </div>
          </div>

          {/* Account Settings Section */}
          <div className="w-full py-8 border-t border-gray-200 mt-8">
            <div className="mb-8">
              <h2 className="text-2xl mb-2 text-primary/90 flex items-center gap-2">
                <span>Account Settings</span>
                <span className="text-base font-normal bg-primary/10 text-primary px-3 py-1 rounded-full">
                  Personal Account
                </span>
              </h2>
              <p className="text-sm text-gray-600">
                Manage your account security and preferences
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {/* Email Section */}
              <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm">
                <h4 className="font-primary text-lg font-bold text-neutral-800 mb-4">
                  Email Address
                </h4>
                <p className="text-sm text-gray-600 mb-4">
                  Change your email here. Note that your previous email will no
                  longer be associated with this account.
                </p>
                <div className="space-y-4">
                  <input
                    type="email"
                    className="w-full p-3 border border-primary/30 rounded-lg focus:ring-2 focus:ring-primary/20 focus:border-primary"
                    value={user?.email || ''}
                    onChange={(e) =>
                      setUser({ ...user, email: e.target.value })
                    }
                    placeholder="Enter new email address"
                  />
                  <button className="w-full md:w-auto px-6 py-3 bg-accent text-white rounded-lg transition-all duration-300 hover:-translate-y-1 hover:shadow-lg hover:shadow-accent/20">
                    Update Email
                  </button>
                </div>
              </div>

              {/* Password Section */}
              <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm">
                <h4 className="font-primary text-lg font-bold text-neutral-800 mb-4">
                  Password
                </h4>
                <p className="text-sm text-gray-600 mb-4">
                  Update your password. You'll be logged out from all other
                  devices.
                </p>
                <div className="space-y-4">
                  <input
                    type="password"
                    className="w-full p-3 border border-primary/30 rounded-lg focus:ring-2 focus:ring-primary/20 focus:border-primary"
                    placeholder="Current password"
                  />
                  <input
                    type="password"
                    className="w-full p-3 border border-primary/30 rounded-lg focus:ring-2 focus:ring-primary/20 focus:border-primary"
                    placeholder="New password"
                  />
                  <button className="w-full md:w-auto px-6 py-3 bg-accent text-white rounded-lg transition-all duration-300 hover:-translate-y-1 hover:shadow-lg hover:shadow-accent/20">
                    Change Password
                  </button>
                </div>
              </div>

              {/* Account Management Section */}
              <div className="md:col-span-2 bg-white p-6 rounded-xl border border-gray-100 shadow-sm">
                <h4 className="font-primary text-lg font-bold text-neutral-800 mb-4 flex items-center">
                  Account Management
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="ml-2 text-error/80"
                  />
                </h4>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="p-4 bg-error/5 rounded-lg">
                    <h5 className="font-semibold mb-2 text-error">
                      Delete Account
                    </h5>
                    <p className="text-sm text-gray-600 mb-4">
                      This action is permanent. All your data will be
                      permanently deleted.
                    </p>
                    <button className="w-full px-6 py-3 bg-white border-2 border-error text-error rounded-lg hover:bg-error hover:text-white transition-colors duration-300">
                      <FontAwesomeIcon icon={faUserXmark} className="mr-2" />
                      Delete My Account
                    </button>
                  </div>

                  <div className="p-4 bg-info/5 rounded-lg">
                    <h5 className="font-semibold mb-2 text-info">
                      Disable Account
                    </h5>
                    <p className="text-sm text-gray-600 mb-4">
                      Temporarily disable your account. You can reactivate it
                      anytime.
                    </p>
                    <button className="w-full px-6 py-3 bg-white border-2 border-info text-info rounded-lg hover:bg-info hover:text-white transition-colors duration-300">
                      <FontAwesomeIcon icon={faUserLock} className="mr-2" />
                      Disable My Account
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default Profile
