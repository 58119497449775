import React, { useState } from 'react'
import Banner from '../../../components/Banner'
import Footer from '../../../components/Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  //faRobot,
  faGraduationCap,
  faChalkboardTeacher,
  faCompass,
  faUniversity,
  //faBook,
  //faQuestion,
  faArrowRight,
  faClock,
  faUserGraduate,
  faChartLine,
  faLock,
} from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../utils/firebase/auth'

// Reusable Component for AI Agent Cards
const AIAgent = ({ title, description, icon }) => {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const { authUser } = useAuth()

  const handleChatClick = async () => {
    if (!authUser) {
      toast.warning('Please sign in to access AI features', {
        position: 'bottom-right',
        autoClose: 5000,
      })
      navigate('/account/login')
      return
    }

    setIsLoading(true)
    await new Promise((resolve) => setTimeout(resolve, 500))
    setIsLoading(false)

    // Create a URL-friendly version of the title
    const agentSlug = title.toLowerCase().replace(/\s+/g, '-')
    navigate(`/chat/${agentSlug}`, {
      state: {
        agentType: title,
        icon: icon,
      },
    })
  }

  return (
    <div className="bg-white rounded-xl p-8 hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1 border border-gray-100 h-full flex flex-col">
      <div className="flex items-center mb-6">
        <div className="bg-primary/10 p-4 rounded-xl mr-4 group-hover:bg-primary/20 transition-all duration-300">
          <FontAwesomeIcon
            icon={icon}
            className="text-4xl text-primary transform group-hover:scale-110 transition-transform duration-300"
          />
        </div>
        <h3 className="text-2xl font-bold text-gray-800">{title}</h3>
      </div>
      <p className="text-gray-600 mb-8 text-lg flex-grow">{description}</p>
      <button
        onClick={handleChatClick}
        disabled={isLoading}
        className="w-full bg-primary text-white px-6 py-3 rounded-xl hover:bg-primary/90 transition-all duration-300 flex items-center justify-center gap-2 group font-semibold disabled:opacity-70 mt-auto"
      >
        {isLoading ? (
          <>
            <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
                fill="none"
              />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
            Initializing...
          </>
        ) : (
          <>
            {!authUser && <FontAwesomeIcon icon={faLock} className="mr-2" />}
            {authUser ? 'Chat Now' : 'Sign in to Chat'}
            {authUser && (
              <FontAwesomeIcon
                icon={faArrowRight}
                className="transform group-hover:translate-x-1 transition-transform"
              />
            )}
          </>
        )}
      </button>
    </div>
  )
}

const KAI = () => {
  const { authUser } = useAuth()

  // AI Agent Data
  const aiAgents = [
    {
      title: 'Study Partner',
      description:
        'Your person study virtual friend for studies, homework help and exam preparation.',
      icon: faGraduationCap,
    },
    {
      title: 'Virtual Ambassador',
      description:
        'Helps Help you with your studies, feel free to ask me questions, .',
      icon: faChalkboardTeacher,
    },
    {
      title: 'Academic Counselor',
      description: 'Guidance on study techniques, how to stay focus, and career planning.',
      icon: faCompass,
    },
    {
      title: 'Scholarship & College Assistant',
      description:
        'Guidance on Searching, and Applying for scholarships and college admission allover the world.',
      icon: faUniversity,
    },
  ]

  return (
    <div className="min-h-screen bg-gray-50">
      <Banner
        title="K-AI Educational Assistants"
        content="Discover how our AI-powered agents can transform your learning and teaching experience. Tailored support for every educational need."
      />

      {!authUser && (
        <div className="container mx-auto px-4 py-6">
          <div className="bg-blue-50 border-l-4 border-blue-500 p-4 rounded-r-lg">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <FontAwesomeIcon icon={faLock} className="text-blue-500" />
              </div>
              <div className="ml-3">
                <p className="text-sm text-blue-700">
                  Please sign in to access our AI features and start chatting
                  with our educational assistants.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Features Overview */}
      <div className="container mx-auto px-4 py-16">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-800 mb-4">
            Your Personal AI Learning Companions
          </h2>
          <p className="text-gray-600 max-w-2xl mx-auto text-lg">
            Our AI assistants are designed to provide personalized support
            across various educational needs. Choose your perfect learning
            partner and start your enhanced learning journey today.
          </p>
        </div>

        {/* AI Agent Cards Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {aiAgents.map((agent, index) => (
            <AIAgent key={index} {...agent} />
          ))}
        </div>

        {/* Benefits Section */}
        <div className="mt-20 grid grid-cols-1 md:grid-cols-3 gap-8">
          {[
            {
              icon: faClock,
              title: '24/7 Availability',
              description: 'Get help whenever you need it, day or night',
            },
            {
              icon: faUserGraduate,
              title: 'Personalized Learning',
              description: 'Adaptive support tailored to your needs',
            },
            {
              icon: faChartLine,
              title: 'Track Progress',
              description: 'Monitor your learning journey in real-time',
            },
          ].map((benefit, index) => (
            <div
              key={index}
              className="text-center p-8 bg-white rounded-xl hover:shadow-lg transition-all duration-300"
            >
              <div className="bg-primary/10 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-6 transform hover:rotate-12 transition-transform">
                <FontAwesomeIcon
                  icon={benefit.icon}
                  className="text-2xl text-primary"
                />
              </div>
              <h3 className="text-xl font-semibold mb-3">{benefit.title}</h3>
              <p className="text-gray-600 leading-relaxed">
                {benefit.description}
              </p>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default KAI
