import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import { useAuth } from '../../../utils/firebase/auth'
import { generateAIResponse } from '../../../utils/openai'
import agentPrompts from '../../../utils/openai/agentPrompts'

const ChatPage = () => {
  const { agentSlug } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const { authUser } = useAuth()
  const [messages, setMessages] = useState([])
  const [input, setInput] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [streamingMessage, setStreamingMessage] = useState('')
  const messagesEndRef = useRef(null)

  const { agentType, icon } = location.state || {}

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages, streamingMessage])

  // Memoize the getInitialMessage function
  const getInitialMessage = useCallback(() => {
    switch (agentSlug) {
      case 'study-partner':
        return "Hello! I'm your AI Study Partner, your virtual friend for academic support. How can I help you with your studies, homework, or exam preparation today?"
      case 'virtual-ambassador':
        return "Hi! I'm your Virtual AI Ambassador. I'm here to help you with your studies and answer any questions you have. What would you like to know?"
      case 'academic-counselor':
        return "Hi! I'm your AI Academic Counselor. I can help you with study techniques, focus strategies, and career planning. How can I assist you today?"
      default:
        return "Hello! I'm your Scholarship & College AI Assistant. I can guide you through scholarship searches and college admissions worldwide. How can I help with your educational journey?"
    }
  }, [agentSlug])

  useEffect(() => {
    if (!authUser) {
      navigate('/account/login')
      return
    }

    // Set initial welcome message based on agent type
    setMessages([
      {
        type: 'bot',
        content: getInitialMessage(),
      },
    ])
  }, [authUser, navigate, agentSlug, getInitialMessage])

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!input.trim()) return

    const userMessage = { type: 'user', content: input }
    setMessages((prev) => [...prev, userMessage])
    setInput('')
    setIsLoading(true)

    try {
      let fullResponse = ''
      setMessages((prev) => [...prev, { type: 'bot', content: '' }])

      // Get the appropriate system prompt for the current agent
      const systemPrompt =
        agentPrompts[agentSlug]?.content ||
        agentPrompts['scholarship-college-assistant'].content

      await generateAIResponse(
        input,
        (chunk) => {
          fullResponse += chunk
          setStreamingMessage(fullResponse)
        },
        systemPrompt
      )

      setMessages((prev) => {
        const newMessages = [...prev]
        newMessages[newMessages.length - 1].content = fullResponse
        return newMessages
      })
    } catch (error) {
      setMessages((prev) => [
        ...prev,
        {
          type: 'bot',
          content: 'I apologize, but I encountered an error. Please try again.',
          isError: true,
        },
      ])
    } finally {
      setIsLoading(false)
      setStreamingMessage('')
    }
  }

  const renderMessage = (content) => {
    return (
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw]}
        className="prose prose-sm max-w-none prose-pre:bg-gray-800 prose-pre:text-white prose-pre:p-4 prose-pre:rounded-lg prose-code:text-primary prose-headings:text-gray-800 prose-p:text-gray-700 prose-strong:text-primary prose-a:text-primary hover:prose-a:text-primary/80"
      >
        {content}
      </ReactMarkdown>
    )
  }

  return (
    <div className="flex flex-col h-screen bg-gray-50">
      {/* Header */}
      <div className="bg-primary px-4 py-4 flex items-center sticky top-0 z-10 shadow-lg">
        <button
          onClick={() => navigate('/k-ai')}
          className="text-white mr-4 hover:bg-primary-700 p-2 rounded-full transition-colors"
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <div className="flex items-center space-x-4">
          <div className="bg-white/10 p-3 rounded-xl">
            <FontAwesomeIcon icon={icon} className="text-white text-xl" />
          </div>
          <div>
            <h1 className="text-white font-semibold text-lg">{agentType}</h1>
            <p className="text-white/70 text-sm">AI Assistant</p>
          </div>
        </div>
      </div>

      {/* Chat Messages */}
      <div className="flex-1 overflow-y-auto p-4 space-y-6">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`flex ${
              message.type === 'user' ? 'justify-end' : 'justify-start'
            }`}
          >
            <div
              className={`max-w-[85%] p-4 rounded-2xl ${
                message.type === 'user'
                  ? 'bg-primary text-white rounded-br-none shadow-md'
                  : 'bg-white shadow-md rounded-bl-none'
              }`}
            >
              {message.type === 'user' ? (
                <p className="text-sm md:text-base">{message.content}</p>
              ) : (
                <div className="text-sm md:text-base">
                  {index === messages.length - 1 && message.type === 'bot'
                    ? renderMessage(streamingMessage || message.content)
                    : renderMessage(message.content)}
                </div>
              )}
            </div>
          </div>
        ))}
        {isLoading && (
          <div className="flex justify-start">
            <div className="bg-white p-4 rounded-xl shadow-sm">
              <div className="flex space-x-2">
                <div className="w-2 h-2 bg-primary/40 rounded-full animate-bounce" />
                <div className="w-2 h-2 bg-primary/40 rounded-full animate-bounce delay-100" />
                <div className="w-2 h-2 bg-primary/40 rounded-full animate-bounce delay-200" />
              </div>
            </div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>

      {/* Input Area */}
      <form onSubmit={handleSubmit} className="p-4 bg-white border-t shadow-lg">
        <div className="flex gap-3 max-w-4xl mx-auto">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Type your message..."
            className="flex-1 p-4 border rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent bg-gray-50 text-gray-900 placeholder:text-gray-500"
          />
          <button
            type="submit"
            disabled={isLoading}
            className="bg-primary text-white p-4 rounded-2xl hover:bg-primary/90 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <FontAwesomeIcon
              icon={faPaperPlane}
              className={`w-5 h-5 ${isLoading ? 'opacity-50' : ''}`}
            />
          </button>
        </div>
      </form>
    </div>
  )
}

export default ChatPage
