import React from 'react'
import Banner from '../../../components/Banner'
import Footer from '../../../components/Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUsers,
  faGraduationCap,
  faHandshake,
  faChartLine,
  faAward,
  faGlobe,
  faRobot,
  faUserGroup,
} from '@fortawesome/free-solid-svg-icons'

import {
  faYoutube,
  faInstagram,
  faLinkedin,
  faFacebook,
} from '@fortawesome/free-brands-svg-icons'

const Reports = () => {
  const socialStats = [
    {
      platform: 'YouTube',
      icon: faYoutube,
      url: 'https://www.youtube.com/@ketacademy',
      stats: [
        { label: 'Subscribers', value: '1.84K+' },
        { label: 'Total Views', value: '88.7+' },
      ],
    },
    {
      platform: 'Instagram',
      icon: faInstagram,
      url: 'https://www.instagram.com/ket.foundation/',
      stats: [
        // { label: 'Followers', value: '3.8K+' },
        // { label: 'Engagement', value: '12%' },
      ],
    },
    {
      platform: 'LinkedIn',
      icon: faLinkedin,
      url: 'https://www.linkedin.com/company/77005269',
      stats: [
        // { label: 'Followers', value: '2.1K+' },
        // { label: 'Post Reach', value: '45K+' },
      ],
    },
    {
      platform: 'Facebook',
      icon: faFacebook,
      url: 'https://www.facebook.com/groups/2008168369341398/',
      stats: [
        // { label: 'Followers', value: '4.5K+' },
        // { label: 'Group Members', value: '2.8K+' },
      ],
    },
  ]

  return (
    <div className="min-h-screen bg-gray-50">
      <Banner
        title="2023-2024 Impact Report"
        content="Transforming Education in Cameroon: Our Journey and Achievements"
      />

      <div className="container mx-auto px-4 py-12 max-w-7xl">
        {/* Key Statistics Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
          <div className="bg-white p-8 rounded-xl shadow-sm hover:shadow-lg transform hover:-translate-y-1 transition-all duration-300">
            <div className="flex items-center justify-center mb-4">
              <div className="bg-primary/10 w-16 h-16 rounded-full flex items-center justify-center transform hover:scale-110 transition-transform duration-300">
                <FontAwesomeIcon
                  icon={faUsers}
                  className="text-3xl text-primary hover:text-accent transition-colors duration-300"
                />
              </div>
            </div>
            <h3 className="text-center text-3xl font-bold text-gray-800 mb-2">
              1500+
            </h3>
            <p className="text-center text-gray-600">Students Impacted</p>
          </div>

          <div className="bg-white p-8 rounded-xl shadow-sm hover:shadow-lg transform hover:-translate-y-1 transition-all duration-300">
            <div className="flex items-center justify-center mb-4">
              <div className="bg-primary/10 w-16 h-16 rounded-full flex items-center justify-center transform hover:scale-110 transition-transform duration-300">
                <FontAwesomeIcon
                  icon={faGraduationCap}
                  className="text-3xl text-primary hover:text-accent transition-colors duration-300"
                />
              </div>
            </div>
            <h3 className="text-center text-3xl font-bold text-gray-800 mb-2">
              25+
            </h3>
            <p className="text-center text-gray-600">Ambassadors/Mentors</p>
          </div>

          <div className="bg-white p-8 rounded-xl shadow-sm hover:shadow-lg transform hover:-translate-y-1 transition-all duration-300">
            <div className="flex items-center justify-center mb-4">
              <div className="bg-primary/10 w-16 h-16 rounded-full flex items-center justify-center transform hover:scale-110 transition-transform duration-300">
                <FontAwesomeIcon
                  icon={faHandshake}
                  className="text-3xl text-primary hover:text-accent transition-colors duration-300"
                />
              </div>
            </div>
            <h3 className="text-center text-3xl font-bold text-gray-800 mb-2">
              3
            </h3>
            <p className="text-center text-gray-600">Active Programs</p>
          </div>
        </div>

        {/* Key Achievements Section */}
        <div className="bg-white rounded-xl shadow-sm hover:shadow-lg transition-all duration-300 p-8 mb-16">
          <h2 className="text-3xl font-bold text-gray-800 mb-8 text-center">
            Key Achievements
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="space-y-8">
              <div className="flex items-start space-x-4 group">
                <div className="bg-primary/10 p-4 rounded-lg transform group-hover:scale-110 transition-all duration-300">
                  <FontAwesomeIcon
                    icon={faChartLine}
                    className="text-xl text-primary group-hover:text-accent transition-colors duration-300"
                  />
                </div>
                <div className="flex-1">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2 group-hover:text-primary transition-colors duration-300">
                    Platform Growth
                  </h3>
                  <p className="text-gray-600 leading-relaxed">
                    Successfully launched our e-learning platform with
                    AI-powered learning companions and personalized study paths.
                  </p>
                </div>
              </div>
              <div className="flex items-start space-x-4 group">
                <div className="bg-primary/10 p-4 rounded-lg transform group-hover:scale-110 transition-all duration-300">
                  <FontAwesomeIcon
                    icon={faAward}
                    className="text-xl text-primary group-hover:text-accent transition-colors duration-300"
                  />
                </div>
                <div className="flex-1">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2 group-hover:text-primary transition-colors duration-300">
                    Student Success
                  </h3>
                  <p className="text-gray-600 leading-relaxed">
                    Improved academic performance through mentorship programs
                    and interactive learning resources.
                  </p>
                </div>
              </div>
            </div>
            <div className="space-y-8">
              <div className="flex items-start space-x-4 group">
                <div className="bg-primary/10 p-4 rounded-lg transform group-hover:scale-110 transition-all duration-300">
                  <FontAwesomeIcon
                    icon={faGlobe}
                    className="text-xl text-primary group-hover:text-accent transition-colors duration-300"
                  />
                </div>
                <div className="flex-1">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2 group-hover:text-primary transition-colors duration-300">
                    Community Impact
                  </h3>
                  <p className="text-gray-600 leading-relaxed">
                    Expanded our reach to multiple regions in Cameroon,
                    providing educational support to underserved communities.
                  </p>
                </div>
              </div>
              <div className="flex items-start space-x-4 group">
                <div className="bg-primary/10 p-4 rounded-lg transform group-hover:scale-110 transition-all duration-300">
                  <FontAwesomeIcon
                    icon={faHandshake}
                    className="text-xl text-primary group-hover:text-accent transition-colors duration-300"
                  />
                </div>
                <div className="flex-1">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2 group-hover:text-primary transition-colors duration-300">
                    Partnerships
                  </h3>
                  <p className="text-gray-600 leading-relaxed">
                    Established strategic partnerships with educational
                    institutions and organizations to enhance our program
                    offerings.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Future Goals Section */}
        <div className="bg-white rounded-xl shadow-sm hover:shadow-lg transition-all duration-300 p-8">
          <h2 className="text-3xl font-bold text-gray-800 mb-8 text-center">
            Looking Ahead: 2025-2026
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="space-y-8">
              <div className="flex items-start space-x-4 group">
                <div className="bg-primary/10 p-4 rounded-lg transform group-hover:scale-110 transition-all duration-300">
                  <FontAwesomeIcon
                    icon={faChartLine}
                    className="text-xl text-primary group-hover:text-accent transition-colors duration-300"
                  />
                </div>
                <div className="flex-1">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2 group-hover:text-primary transition-colors duration-300">
                    Expanding Our Reach
                  </h3>
                  <p className="text-gray-600 leading-relaxed">
                    Goal to impact 15,000+ students across Cameroon through our
                    digital platform and mentorship programs, with focus on
                    rural areas.
                  </p>
                </div>
              </div>
              <div className="flex items-start space-x-4 group">
                <div className="bg-primary/10 p-4 rounded-lg transform group-hover:scale-110 transition-all duration-300">
                  <FontAwesomeIcon
                    icon={faRobot}
                    className="text-xl text-primary group-hover:text-accent transition-colors duration-300"
                  />
                </div>
                <div className="flex-1">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2 group-hover:text-primary transition-colors duration-300">
                    Enhanced AI Integration
                  </h3>
                  <p className="text-gray-600 leading-relaxed">
                    Developing more sophisticated AI learning tools to provide
                    personalized educational support and adaptive learning
                    paths.
                  </p>
                </div>
              </div>
            </div>
            <div className="space-y-8">
              <div className="flex items-start space-x-4 group">
                <div className="bg-primary/10 p-4 rounded-lg transform group-hover:scale-110 transition-all duration-300">
                  <FontAwesomeIcon
                    icon={faUserGroup}
                    className="text-xl text-primary group-hover:text-accent transition-colors duration-300"
                  />
                </div>
                <div className="flex-1">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2 group-hover:text-primary transition-colors duration-300">
                    Student Ambassadors Program
                  </h3>
                  <p className="text-gray-600 leading-relaxed">
                    Expanding our ambassador network to 100+ students across
                    different regions, empowering youth leaders to drive
                    educational change.
                  </p>
                </div>
              </div>
              <div className="flex items-start space-x-4 group">
                <div className="bg-primary/10 p-4 rounded-lg transform group-hover:scale-110 transition-all duration-300">
                  <FontAwesomeIcon
                    icon={faHandshake}
                    className="text-xl text-primary group-hover:text-accent transition-colors duration-300"
                  />
                </div>
                <div className="flex-1">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2 group-hover:text-primary transition-colors duration-300">
                    Strategic Partnerships
                  </h3>
                  <p className="text-gray-600 leading-relaxed">
                    Establishing partnerships with 50+ high schools and
                    educational organizations to create a comprehensive support
                    network.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Digital Impact section at bottom */}
      <div className="border-t border-gray-200 bg-white py-8">
        <div className="container mx-auto px-4 max-w-7xl">
          <h2 className="text-xl font-semibold text-gray-800 mb-6 text-center">
            Our Digital Presence
          </h2>
          <div className="flex flex-wrap justify-center items-center gap-8">
            {socialStats.map((platform, index) => (
              <div key={index} className="flex items-center space-x-3 group">
                <a
                  href={platform.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`w-10 h-10 rounded-full flex items-center justify-center transform group-hover:scale-110 transition-all duration-300 ${
                    platform.platform === 'YouTube'
                      ? 'bg-red-100 hover:bg-red-200'
                      : platform.platform === 'Instagram'
                      ? 'bg-pink-100 hover:bg-pink-200'
                      : platform.platform === 'Facebook'
                      ? 'bg-blue-100 hover:bg-blue-200'
                      : 'bg-[#0077b5]/10 hover:bg-[#0077b5]/20'
                  }`}
                >
                  <FontAwesomeIcon
                    icon={platform.icon}
                    className={`text-lg ${
                      platform.platform === 'YouTube'
                        ? 'text-red-600'
                        : platform.platform === 'Instagram'
                        ? 'text-pink-600'
                        : platform.platform === 'Facebook'
                        ? 'text-blue-600'
                        : 'text-[#0077b5]'
                    }`}
                  />
                </a>
                <div className="flex flex-col">
                  {platform.stats.map((stat, idx) => (
                    <div key={idx} className="flex items-center space-x-2">
                      <span className="text-sm font-semibold text-gray-800">
                        {stat.value}
                      </span>
                      <span className="text-xs text-gray-500">
                        {stat.label}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default Reports
