import React from 'react'
//import BreadCrumb from '../../BreadCrumb'
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBell,
  faPlus,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import Calendar from 'react-calendar'
import courseImage from '../../../assets/images/library/Courses.png'
import 'react-calendar/dist/Calendar.css'
import './calendar-override.css'
import BreadCrumb from '../../BreadCrumb'

const DashboardHome = function () {
  // Upcoming assignments data
  const assignments = [
    {
      title: 'History Assignment - Find out how the wheel was invented.',
      course: 'History Course',
      dueDate: 'Oct 28',
      icon: faBell,
    },
    {
      title: 'Exam',
      course: 'History Course',
      dueDate: 'Oct 28',
      icon: faBell,
    },
  ]

  // Ongoing courses data
  const courses = [
    {
      title: 'Linear Algebra',
      progress: 60,
      imageUrl: courseImage,
    },
    {
      title: 'History',
      progress: 45,
      imageUrl: courseImage,
    },
    {
      title: 'Chemistry',
      progress: 30,
      imageUrl: courseImage,
    },
  ]

  // Add Popular Resources data
  const popularResources = [
    {
      title: 'History Course',
      author: 'Diana Richmonds',
      duration: '72 hours',
      description:
        'Gain valuable insights, broaden your historical perspective, and explore the narratives that define our shared heritage.',
      imageUrl: courseImage,
    },
    {
      title: 'History Course',
      author: 'Diana Richmonds',
      duration: '72 hours',
      description:
        'Gain valuable insights, broaden your historical perspective, and explore the narratives that define our shared heritage.',
      imageUrl: courseImage,
    },
  ]

  return (
    <main className="dashboard bg-gray-50 min-h-screen p-6">
      <BreadCrumb
        title="Dashboard"
        slugs={[
          {
            display: 'Dashboard',
            to: '/dashboard/home',
            active: true,
          },
        ]}
      />

      {/* Upcoming Assignments Section */}
      <div className="mb-8">
        <h2 className="text-2xl font-bold text-gray-800 mb-4 flex items-center gap-2">
          Upcoming Assignments
          <span className="text-sm font-normal text-gray-500 bg-gray-100 px-2 py-1 rounded-full">
            {assignments.length} pending
          </span>
        </h2>
        <div className="bg-[#1f4690] rounded-xl p-4 space-y-3">
          {assignments.map((assignment, index) => (
            <div
              key={index}
              className="bg-white rounded-lg p-4 flex justify-between items-center hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1"
            >
              <div className="flex items-center gap-4">
                <div className="bg-[#1f4690] p-3 rounded-lg">
                  <FontAwesomeIcon
                    icon={assignment.icon}
                    className="text-white"
                  />
                </div>
                <div>
                  <h3 className="font-medium text-gray-800">
                    {assignment.title}
                  </h3>
                  <p className="text-sm text-gray-600">{assignment.course}</p>
                </div>
              </div>
              <div className="flex items-center gap-4">
                <div className="text-right">
                  <span className="text-sm text-gray-600">Due Date:</span>
                  <span className="text-sm text-[#1f4690] ml-2">
                    {assignment.dueDate}
                  </span>
                </div>
                <button className="bg-[#1f4690] text-white px-6 py-2.5 rounded-lg hover:bg-[#1a3a7a] transition-all duration-300 flex items-center gap-2 group">
                  Do it now
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className="transform group-hover:translate-x-1 transition-transform"
                  />
                </button>
              </div>
            </div>
          ))}
          <div className="flex justify-end mt-3">
            <button className="text-white hover:text-gray-200 transition-colors text-sm font-medium px-4 py-2 hover:bg-[#1a3a7a] rounded-lg flex items-center gap-2">
              See all assignments
              <FontAwesomeIcon icon={faChevronRight} className="text-xs" />
            </button>
          </div>
        </div>
      </div>

      {/* Grid Layout for Ongoing Courses and Calendar */}
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
        {/* Ongoing Courses Section - Takes up 3 columns */}
        <div className="lg:col-span-3">
          <div className="bg-white rounded-xl p-6 shadow-sm hover:shadow-md transition-all duration-300">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold text-gray-800">
                Ongoing Courses
              </h2>
              <button className="flex items-center text-[#1a365d] hover:text-[#2d4a7c]">
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                New Course
              </button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {courses.map((course, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg shadow-sm overflow-hidden border border-gray-100 hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1 cursor-pointer"
                >
                  <div className="relative">
                    <img
                      src={course.imageUrl}
                      alt={course.title}
                      className="w-full h-32 object-cover"
                    />
                    <div className="absolute top-2 right-2 bg-white px-2 py-1 rounded-full text-xs font-medium text-gray-600">
                      {course.progress >= 75 ? 'Almost Done!' : 'In Progress'}
                    </div>
                  </div>
                  <div className="p-4">
                    <h3 className="font-medium text-gray-800 mb-3">
                      {course.title}
                    </h3>
                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                      <div
                        className="bg-[#1a365d] h-2.5 rounded-full transition-all duration-700 ease-in-out"
                        style={{ width: `${course.progress}%` }}
                      >
                        <div className="animate-pulse bg-opacity-50"></div>
                      </div>
                    </div>
                    <p className="text-sm text-gray-600 mt-2 flex justify-between">
                      <span>{course.progress}% Complete</span>
                      <span className="text-[#1a365d]">
                        {100 - course.progress}% remaining
                      </span>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Calendar Section - Takes up 1 column */}
        <div className="lg:col-span-1">
          <div className="bg-white rounded-xl p-6 shadow-sm hover:shadow-md transition-all duration-300">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              Calendar
            </h2>
            <Calendar
              className="w-full border-0 shadow-sm rounded-lg"
              tileClassName={({ date }) => {
                const today = new Date()
                const isToday = date.toDateString() === today.toDateString()
                const isSelected = date.getDate() === 26
                const hasEvent = [15, 20, 26].includes(date.getDate()) // Example dates with events

                return `
                  h-10 
                  flex items-center justify-center 
                  text-sm font-medium 
                  rounded-lg
                  transition-all duration-300
                  relative
                  ${isToday ? 'bg-[#1f4690] text-white hover:bg-[#1a3a7a]' : ''}
                  ${
                    isSelected
                      ? 'bg-[#1f4690] text-white hover:bg-[#1a3a7a]'
                      : ''
                  }
                  ${
                    hasEvent && !isToday && !isSelected
                      ? 'font-bold text-[#1f4690]'
                      : ''
                  }
                  ${!isToday && !isSelected ? 'hover:bg-gray-100' : ''}
                `
              }}
              navigationLabel={({ date }) => (
                <span className="text-lg font-semibold text-gray-800">
                  {date.toLocaleString('default', {
                    month: 'long',
                    year: 'numeric',
                  })}
                </span>
              )}
              prevLabel={
                <button className="p-1 hover:bg-gray-100 rounded-full transition-colors">
                  <span className="text-gray-600 text-lg">‹</span>
                </button>
              }
              nextLabel={
                <button className="p-1 hover:bg-gray-100 rounded-full transition-colors">
                  <span className="text-gray-600 text-lg">›</span>
                </button>
              }
              formatShortWeekday={(locale, date) => {
                return ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'][
                  date.getDay()
                ]
              }}
              formatDay={(locale, date) => date.getDate()}
              calendarType="US"
            />
          </div>
        </div>
      </div>

      {/* Popular Resources Section */}
      <div className="mt-8">
        <h2 className="text-xl font-semibold text-gray-800 mb-4">
          Popular Resources
        </h2>
        <div className="bg-white rounded-2xl p-6 shadow-sm hover:shadow-md transition-all duration-300">
          <div className="space-y-4">
            {popularResources.map((resource, index) => (
              <div
                key={index}
                className="p-6 rounded-2xl bg-white border border-gray-100 hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1"
              >
                <div className="flex gap-6">
                  {/* Image Container */}
                  <div className="flex-shrink-0">
                    <img
                      src={resource.imageUrl}
                      alt={resource.title}
                      className="w-28 h-28 object-cover rounded-lg"
                    />
                  </div>

                  {/* Content Container */}
                  <div className="flex-grow">
                    <div className="flex justify-between items-start">
                      {/* Title and Author */}
                      <div>
                        <h3 className="text-lg font-semibold text-gray-800">
                          {resource.title}
                        </h3>
                        <p className="text-sm text-gray-600 mt-1">
                          By {resource.author}
                        </p>
                      </div>

                      {/* Duration and Enroll Button */}
                      <div className="flex items-center gap-8">
                        <span className="text-gray-700 font-medium">
                          {resource.duration}
                        </span>
                        <button className="bg-[#1a365d] hover:bg-[#2d4a7c] text-white px-6 py-2.5 rounded-lg transition-all duration-300 flex items-center gap-2 group">
                          Enroll Now
                          <FontAwesomeIcon
                            icon={faChevronRight}
                            className="transform group-hover:translate-x-1 transition-transform"
                          />
                        </button>
                      </div>
                    </div>

                    {/* Description */}
                    <p className="text-gray-600 text-sm mt-4 leading-relaxed">
                      {resource.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </main>
  )
}

export default DashboardHome
