import {
  faBookAtlas,
  faCalendar,
  faCommentDots,
  faTableColumns,
  faUserGear,
  faUserGroup,
} from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../Footer'
import DashboardHeader from '../Header'
import DashboardSideNavBar from '../SideNav'

// Navigation link configuration with updated structure and new categories
const links = [
  {
    text: 'Dashboard',
    link: '/dashboard/home',
    faIcon: faTableColumns,
    description: 'Overview of your learning progress and activities',
  },
  {
    text: 'Courses',
    link: '/dashboard/courses',
    faIcon: faBookAtlas,
    description: 'Access your enrolled courses',
  },
  {
    text: 'Instructors',
    link: '/dashboard/instructors',
    faIcon: faUserGroup,
    description: 'View your course instructors',
  },
  {
    text: 'My Schedule',
    link: '/dashboard/schedule',
    faIcon: faCalendar,
    description: 'View your class schedule and deadlines',
  },
  {
    text: 'Resources',
    link: '/dashboard/resources',
    faIcon: faBookAtlas,
    description: 'Access learning materials and resources',
  },
  {
    text: 'Chats',
    link: '/dashboard/chats',
    faIcon: faCommentDots,
    description: 'Message your instructors and classmates',
  },
  {
    text: 'Profile',
    link: '/dashboard/profile',
    faIcon: faUserGear,
    description: 'Manage your personal information',
    subItems: [
      {
        text: 'Personal Info',
        link: '/dashboard/profile/personal-info',
      },
      {
        text: 'Privacy & Security',
        link: '/dashboard/profile/security',
      },
    ],
  },
]

const Dashboard = function ({ children }) {
  // State to control sidebar visibility
  const [sideBarShowing, setSideBarShowing] = useState(false)

  // Toggle function to show/hide sidebar
  const toggleSidebar = () =>
    setSideBarShowing((sideBarShowing) => !sideBarShowing)

  return (
    <div className="flex flex-col min-h-screen">
      <DashboardHeader onNavToggle={toggleSidebar} />

      <div className="flex flex-1 relative">
        <DashboardSideNavBar
          sideBarShowing={sideBarShowing}
          toggleSidebar={toggleSidebar}
          linkItems={links}
        />

        <main
          className={`flex-1 p-4 transition-all duration-300 ease-in-out
          ${sideBarShowing ? 'ml-64 md:ml-64' : 'ml-0'}`}
        >
          <Outlet />
          {children}
        </main>
      </div>

      <Footer />
    </div>
  )
}

export default Dashboard
