import { collection, getDocs, query, where } from 'firebase/firestore'
import { capitalize } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import laptop from '../../../assets/images/library/laptop1.png'
import Alert from '../../../components/Alert'
import Footer from '../../../components/Footer'
import { categories, levels, subjects } from '../../../utils/constants' // Added subjects import
import { firestore } from '../../../utils/firebase'
import { useAuth } from '../../../utils/firebase/auth'

import {
  faBook,
  faChevronDown,
  faFileAlt,
  faSpinner,
  faTimes,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { ChevronRight } from 'react-bootstrap-icons'
import { GlobalWorkerOptions } from 'pdfjs-dist'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'

GlobalWorkerOptions.workerSrc = pdfjsWorker

function Library() {
  const [selectedLevels, setSelectedLevels] = useState([])
  const [selectedTypes, setSelectedTypes] = useState([])
  // const [selectedSystems, setSelectedSystems] = useState([])
  const [selectedSubject, setSelectedSubject] = useState('') // Changed to single subject selection
  const [resources, setResources] = useState([])
  const [filteredResources, setFilteredResources] = useState([])
  const [selectedResource, setSelectedResource] = useState(null)
  const [loading, setLoading] = useState(true)
  const { authUser } = useAuth()
  const [keywords, setKeywords] = useState('')

  const fetchRandomResources = useCallback(async () => {
    const resourcesRef = collection(firestore, 'resources')
    const q = query(resourcesRef)
    const querySnapshot = await getDocs(q)
    const fetchedResources = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))
    const shuffled = fetchedResources.sort(() => 0.5 - Math.random())
    setResources(shuffled.slice(0, 10))
    setFilteredResources(shuffled.slice(0, 10))
    setLoading(false)
  }, [])

  useEffect(() => {
    fetchRandomResources()
  }, [fetchRandomResources])

  const handleSearch = async () => {
    if (!authUser) {
      Alert.fire({
        icon: 'warning',
        title: 'Login Required',
        text: 'Please log in to search for resources.',
        showCancelButton: true,
        confirmButtonText: 'Log In',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = '/account/login'
        }
      })
      return
    }

    if (!keywords.trim()) {
      Alert.fire({
        icon: 'warning',
        title: 'Empty Search Query',
        text: 'Please enter keywords to search for resources.',
      })
      return
    }

    const resourcesRef = collection(firestore, 'resources')

    // Split and normalize keywords
    const searchKeywords = keywords
      .toLowerCase()
      .trim()
      .split(/\s+/)
      .filter((keyword) => keyword.length >= 2) // Only use keywords with 2 or more characters

    if (searchKeywords.length === 0) {
      Alert.fire({
        icon: 'warning',
        title: 'Invalid Keywords',
        text: 'Please enter valid keywords for search.',
      })
      return
    }

    try {
      // Query using array-contains-any for keywords
      const q = query(
        resourcesRef,
        where('keywords', 'array-contains-any', searchKeywords)
      )

      const querySnapshot = await getDocs(q)
      const searchResults = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))

      setResources(searchResults)
      setFilteredResources(searchResults)

      if (searchResults.length === 0) {
        Alert.fire({
          icon: 'info',
          title: 'No Results',
          text: 'No resources were found matching your search keywords.',
        })
      }

      // Reset filters when performing a search
      setSelectedLevels([])
      setSelectedTypes([])
      setSelectedSubject('')
    } catch (error) {
      console.error('Search error:', error)
      Alert.fire({
        icon: 'error',
        title: 'Search Error',
        text: 'An error occurred while searching. Please try again.',
      })
    }
  }

  const applyFilters = useCallback(() => {
    let filtered = resources
    if (selectedLevels.length > 0) {
      filtered = filtered.filter((resource) =>
        selectedLevels.includes(resource.level)
      )
    }
    if (selectedTypes.length > 0) {
      filtered = filtered.filter((resource) =>
        selectedTypes.includes(resource.resourceType)
      )
    }
    // if (selectedSystems.length > 0) {
    //   filtered = filtered.filter((resource) =>
    //     selectedSystems.includes(resource.system)
    //   )
    // }
    if (selectedSubject) {
      // Changed to single subject filter
      filtered = filtered.filter(
        (resource) => resource.subject === selectedSubject
      )
    }
    setFilteredResources(filtered)
  }, [
    resources,
    selectedLevels,
    selectedTypes,
    // selectedSystems,
    selectedSubject,
  ])

  useEffect(() => {
    applyFilters()
  }, [applyFilters])

  const handleFilterChange = (filterType, value) => {
    switch (filterType) {
      case 'level':
        setSelectedLevels(value ? [value] : [])
        break
      case 'type':
        setSelectedTypes(value ? [value] : [])
        break
      case 'subject':
        setSelectedSubject(value)
        break
      default:
        break
    }
  }

  const handleResourceClick = (resource) => {
    setSelectedResource(resource)
  }

  const closeResourceModal = () => {
    setSelectedResource(null)
  }

  const shareResource = (resource) => {
    if (!resource || !resource.id) {
      console.error('Invalid resource or missing ID')
      return
    }

    const shareUrl = `${window.location.origin}/resource/${resource.id}`
    console.log('Share URL:', shareUrl) // Log the URL for verification

    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        Alert.fire({
          icon: 'success',
          title: 'Link Copied',
          text: 'The link has been copied to your clipboard.',
        })
      })
      .catch((err) => {
        console.error('Failed to copy: ', err)
      })
  }

  const ResourceCard = ({ resource, onClick }) => (
    <div
      className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer transition-transform duration-300 hover:shadow-xl hover:scale-105 flex flex-col h-full w-full"
      onClick={() => onClick(resource)}
    >
      <div className="h-48 overflow-hidden relative">
        {resource.resourceType === categories.YOUTUBE ? (
          <img
            src={getYouTubeThumbnail(resource.resourceUrl)}
            alt={resource.title}
            className="w-full h-full object-cover"
          />
        ) : (
          <embed
            src={`${resource.resourceUrl}#toolbar=0&navpanes=0&scrollbar=0`}
            type="application/pdf"
            width="100%"
            height="100%"
            className="w-full h-full object-cover"
          />
        )}
        <div className="absolute top-0 right-0 bg-blue-600 text-white px-2 py-1 text-xs font-semibold rounded-bl-lg">
          {resource.resourceType}
        </div>
      </div>
      <div className="p-4 flex-grow flex flex-col">
        <h3 className="text-md font-semibold mb-2 line-clamp-2 flex-grow">
          {capitalize(resource.title)}
        </h3>
        <p className="text-sm text-gray-600 mb-1">
          <span className="font-medium">Subject:</span> {resource.subject}
        </p>
        <p className="text-sm text-gray-600">
          <span className="font-medium">Level:</span> {resource.level}
        </p>
      </div>
      <div className="bg-gray-100 px-4 py-2 flex items-center justify-between">
        <button
          className="bg-blue-600 text-white px-1 py-1 rounded-lg hover:bg-blue-700 transition-colors"
          onClick={(e) => {
            e.stopPropagation() // Prevent card click event
            onClick(resource)
          }}
        >
          Open
        </button>
        <button
          className="bg-green-600 text-white px-1 py-1 rounded-lg hover:bg-green-700 transition-colors"
          onClick={(e) => {
            e.stopPropagation() // Prevent card click event
            shareResource(resource)
          }}
        >
          Share
        </button>
      </div>
    </div>
  )

  return (
    <div className="overflow-x-hidden">
      <div className="bg-primary text-center mt-24 py-12 flex flex-col lg:flex-row justify-center items-center w-full">
        <div className="flex flex-col lg:flex-row w-full max-w-6xl justify-between items-center px-4 lg:px-8">
          <div className="text-container w-full lg:w-1/2 text-left text-white">
            <h1 className="text-4xl lg:text-5xl font-bold mb-4">
              Get Free Access to Our Courses
            </h1>
            <h2 className="text-2xl lg:text-3xl font-semibold text-orange-500 mb-4">
              ON ALL DEVICES
            </h2>
            <p className="text-lg">
              Access all our free videos and PDF resources
            </p>
          </div>
          <div className="image-container w-full lg:w-1/2 flex justify-center items-center mt-8 lg:mt-0">
            <img
              src={laptop}
              alt="Laptop"
              className="max-w-full h-auto"
              style={{ transform: 'scale(1.1)' }}
            />
          </div>
        </div>
      </div>

      <div className="bg-gray-100 p-8">
        <h1 className="text-4xl lg:text-5xl font-semibold text-orange-500 text-center mb-4">
          Browse Resources
        </h1>
        <p className="text-center text-lg mb-8">
          Explore a wide range of subjects and topics, and find the perfect
          <br /> resource to kickstart your educational journey
        </p>
        <div className="pb-10 flex flex-col items-center">
          <div className="mt-6 flex flex-col sm:flex-row justify-center w-full max-w-2xl mb-4">
            <input
              type="text"
              placeholder="Enter keywords to search resources"
              className="border-2 border-primary rounded-lg px-4 py-3 w-full sm:w-96 mb-2 sm:mb-0"
              value={keywords}
              onChange={(event) => setKeywords(event.target.value)}
            />
            <button
              className="bg-primary text-white px-6 py-3 rounded-lg hover:bg-opacity-80 transition-colors sm:ml-2 w-full sm:w-auto"
              onClick={handleSearch}
            >
              Search
            </button>
          </div>

          {/* Only show filters when search bar is empty */}
          {!keywords && (
            <div className="flex flex-wrap justify-center gap-4 mb-4">
              <div>
                <h3 className="font-semibold mb-2">Subject:</h3>
                <div className="relative">
                  <select
                    value={selectedSubject}
                    onChange={(e) =>
                      handleFilterChange('subject', e.target.value)
                    }
                    className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-primary"
                  >
                    <option value="">Select a subject</option>
                    {Object.values(subjects).map((subject) => (
                      <option key={subject} value={subject}>
                        {subject}
                      </option>
                    ))}
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      className="w-4 h-4 text-gray-500"
                    />
                  </div>
                </div>
              </div>

              <div>
                <h3 className="font-semibold mb-2">Level:</h3>
                <div className="relative">
                  <select
                    value={selectedLevels[0] || ''}
                    onChange={(e) =>
                      handleFilterChange('level', e.target.value)
                    }
                    className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-primary"
                  >
                    <option value="">Select a level</option>
                    {Object.values(levels)
                      .flat()
                      .map((level) => (
                        <option key={level} value={level}>
                          {level}
                        </option>
                      ))}
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      className="w-4 h-4 text-gray-500"
                    />
                  </div>
                </div>
              </div>

              <div>
                <h3 className="font-semibold mb-2">Type:</h3>
                <div className="relative">
                  <select
                    value={selectedTypes[0] || ''}
                    onChange={(e) => handleFilterChange('type', e.target.value)}
                    className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-primary"
                  >
                    <option value="">Select a type</option>
                    {Object.values(categories).map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      className="w-4 h-4 text-gray-500"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {loading ? (
          <div className="text-center mt-4 animate__animated animate__fadeIn">
            <div className="flex justify-center items-center mb-4">
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                className="text-primary"
                size="3x"
              />
            </div>
            <h2 className="text-xl font-semibold animate__animated animate__fadeIn">
              Loading resources...
            </h2>
          </div>
        ) : filteredResources.length === 0 ? (
          <div className="text-center mt-4">
            <h2 className="text-xl font-semibold animate__animated animate__bounceIn">
              No results found
            </h2>
            <p className="text-gray-500 mt-2 animate__animated animate__fadeInUp">
              Your search returned no results. Please try different keywords.
            </p>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 justify-items-center items-center">
            {filteredResources.map((resource) => (
              <ResourceCard
                key={resource.id}
                resource={resource}
                onClick={handleResourceClick}
              />
            ))}
          </div>
        )}
      </div>

      {selectedResource && (
        <ResourceModal
          resource={selectedResource}
          onClose={closeResourceModal}
        />
      )}

      <Footer />
    </div>
  )
}

/*const Checkbox = ({ label, onChange, checked }) => (
  <label className="block text-sm">
    <input
      type="checkbox"
      className="form-checkbox h-4 w-4 text-blue-600"
      onChange={onChange}
      checked={checked}
    />
    <span className="ml-2 text-gray-700">{label}</span>
  </label>
)*/

function getYouTubeThumbnail(url) {
  let videoId

  // Handle youtu.be URLs
  if (url.includes('youtu.be')) {
    videoId = url.split('youtu.be/')[1]
  }
  // Handle standard youtube.com URLs
  else if (url.includes('youtube.com')) {
    videoId = url.split('v=')[1]
  }

  // Remove any additional parameters
  if (videoId) {
    const ampersandPosition = videoId.indexOf('&')
    const questionMarkPosition = videoId.indexOf('?')
    if (ampersandPosition !== -1) {
      videoId = videoId.substring(0, ampersandPosition)
    } else if (questionMarkPosition !== -1) {
      videoId = videoId.substring(0, questionMarkPosition)
    }
  }

  // Construct the thumbnail URL
  return videoId
    ? `https://img.youtube.com/vi/${videoId}/hqdefault.jpg` // Use high-quality thumbnail
    : `https://www.youtube.com/yts/img/yt_1200-vfl4C3T0K.png`
}

const ResourceModal = ({ resource, onClose }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-20">
    <div className="bg-white rounded-lg p-6 w-11/12 h-[90vh] flex flex-col shadow-2xl">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-md md:text-xl font-bold text-primary">
          {capitalize(resource.title)}
        </h2>
        <button
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 transition-colors"
        >
          <FontAwesomeIcon icon={faTimes} className="w-6 h-6" />
        </button>
      </div>
      <div className="bg-gray-100 rounded-lg p-4 mb-4 grid grid-cols-1 gap-2 md:grid-cols-3">
        <div className="flex items-center">
          <FontAwesomeIcon icon={faUser} className="text-primary mr-2" />
          <p className="text-sm text-gray-700">
            Subject: <span className="font-semibold">{resource.subject}</span>
          </p>
        </div>
        <div className="flex items-center">
          <FontAwesomeIcon icon={faFileAlt} className="text-primary mr-2" />
          <p className="text-sm text-gray-700">
            Type: <span className="font-semibold">{resource.resourceType}</span>
          </p>
        </div>
        <div className="flex items-center">
          <FontAwesomeIcon icon={faBook} className="text-primary mr-2" />
          <p className="text-sm text-gray-700">
            Level: <span className="font-semibold">{resource.level}</span>
          </p>
        </div>
      </div>
      <div className="flex-grow overflow-hidden rounded-lg shadow-inner">
        {resource.resourceType === categories.YOUTUBE ? (
          <div className="w-full h-full">
            <ReactPlayer
              url={resource.resourceUrl}
              width="100%"
              height="100%"
              controls={true}
              light={true}
              config={{
                youtube: {
                  playerVars: { modestbranding: 1 },
                },
              }}
            />
          </div>
        ) : (
          <embed
            src={`${resource.resourceUrl}#toolbar=1&navpanes=0&scrollbar=0`}
            type="application/pdf"
            width="100%"
            height="100%"
            className="rounded-lg"
          />
        )}
      </div>
    </div>
  </div>
)

export default Library
