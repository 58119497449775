// Importing necessary React components and assets
import React, { useMemo, useState } from 'react'
// import image from '../../../assets/images/donation/Sponsor-bro.png'
import Banner from '../../../components/Banner'
import Footer from '../../../components/Footer'

// Component for rendering individual donation buttons
const DonationButton = ({ amount, isActive, onClick }) => (
  <button
    type="button"
    onClick={() => onClick(amount)}
    className={`py-2 px-4 rounded ${
      isActive ? 'bg-blue-700 text-white' : 'bg-gray-200'
    }`}
    aria-label={`Donate ${amount} dollars`}
    role="radio"
    aria-checked={isActive}
  >
    ${amount}
  </button>
)

// Component for rendering input fields with dynamic IDs, labels, and placeholders
const InputField = ({ id, label, type, placeholder }) => (
  <div
    className={`w-full ${id !== 'phone' ? 'md:w-1/2' : ''} px-3 mb-6 md:mb-0`}
  >
    <label
      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
      htmlFor={id}
    >
      {label}
    </label>
    <input
      className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
      id={id}
      type={type}
      placeholder={placeholder}
      aria-label={label}
    />
  </div>
)

// Main component for the Donations page
const Donations = () => {
  const [donationAmount, setDonationAmount] = useState('')
  const donationOptions = useMemo(() => ['25', '55', '75', '150'], [])

  const handleSetAmount = (amount) => {
    if (donationAmount !== amount) {
      setDonationAmount(amount)
    }
  }

  return (
    <>
      <Banner title="Support our cause Today" />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Hero Section */}
        <div className="flex flex-col items-center py-12 text-center">
          <p className="text-gray-600 text-xl mb-8 max-w-3xl">
            Join Us in Making Quality Education Accessible to Every Student in
            Cameroon
          </p>

          {/* Our Story Section */}
          <div className="bg-gray-50 p-8 rounded-lg mb-12 max-w-3xl w-full">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Our Journey
            </h2>
            <p className="text-gray-600 mb-6 leading-relaxed">
              KET Academy has been developed from the ground up, primarily
              supported by our founder who is still a university student working
              student jobs to keep our mission alive. Every contribution, no
              matter the size, helps us expand our reach and impact more
              students.
            </p>
            <div className="flex justify-center space-x-8">
              <div className="text-center transform hover:scale-105 transition-all duration-300">
                <span className="block text-4xl font-bold bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent animate-pulse">
                  200+
                </span>
                <span className="text-gray-500 font-medium">
                  Students Impacted
                </span>
              </div>
              <div className="text-center transform hover:scale-105 transition-all duration-300">
                <span className="block text-4xl font-bold bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent animate-pulse">
                  15+
                </span>
                <span className="text-gray-500 font-medium">
                  Expert Mentors
                </span>
              </div>
              <div className="text-center transform hover:scale-105 transition-all duration-300">
                <span className="block text-4xl font-bold bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent animate-pulse">
                  5
                </span>
                <span className="text-gray-500 font-medium">
                  Active Programs
                </span>
              </div>
            </div>
          </div>

          {/* Donation Options */}
          <div className="w-full max-w-3xl">
            <h2 className="text-2xl font-semibold mb-8">
              Choose How to Support Us
            </h2>

            {/* Direct Donation */}
            <div className="bg-white p-8 rounded-lg shadow-md mb-8">
              <h3 className="text-xl font-semibold mb-6">
                Make a Direct Donation
              </h3>
              <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
                {donationOptions.map((amount) => (
                  <DonationButton
                    key={amount}
                    amount={amount}
                    isActive={donationAmount === amount}
                    onClick={handleSetAmount}
                  />
                ))}
              </div>
              <input
                type="number"
                placeholder="Other amount"
                className="w-full border border-gray-300 rounded-lg py-3 px-4 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                onChange={(e) => handleSetAmount(e.target.value)}
                aria-label="Enter other donation amount"
              />
            </div>

            {/* GoFundMe Section */}
            <div className="bg-white p-8 rounded-lg shadow-md mb-8">
              <h3 className="text-xl font-semibold mb-4">
                Support our GoFundMe Campaign
              </h3>
              <p className="text-gray-600 mb-6">
                Help us reach our goal of expanding our educational programs
                across Cameroon.
              </p>
              <a
                href="https://gofundme.com/ket-academy"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block bg-[#00b964] hover:bg-[#00a057] text-white font-bold py-3 px-6 rounded-lg transition duration-300"
              >
                Donate on GoFundMe
              </a>
            </div>

            {/* Donor Information Form */}
            <form className="bg-white p-8 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-6">Your Information</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <InputField
                  id="first-name"
                  label="First Name"
                  type="text"
                  placeholder="Jane"
                />
                <InputField
                  id="last-name"
                  label="Last Name"
                  type="text"
                  placeholder="Doe"
                />
                <InputField
                  id="email"
                  label="Email"
                  type="email"
                  placeholder="you@example.com"
                />
                <InputField
                  id="phone"
                  label="Phone Number"
                  type="tel"
                  placeholder="(555) 555-5555"
                />
              </div>

              {/* Payment Methods */}
              <div className="mt-8">
                <h3 className="text-lg font-semibold mb-4">
                  Choose Payment Method
                </h3>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                  <PaymentButton
                    icon="fab fa-paypal"
                    label="PayPal"
                    className="bg-[#003087] hover:bg-[#003087]/90 text-white"
                  />
                  <PaymentButton
                    icon="far fa-credit-card"
                    label="Card"
                    className="bg-gradient-to-r from-purple-500 to-purple-600 hover:from-purple-600 hover:to-purple-700 text-white"
                  />
                  <PaymentButton
                    icon="fas fa-university"
                    label="Bank"
                    className="bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white"
                  />
                  <PaymentButton
                    icon="fas fa-mobile-alt"
                    label="Mobile Money"
                    className="bg-gradient-to-r from-yellow-500 to-yellow-600 hover:from-yellow-600 hover:to-yellow-700 text-white"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
      <DonationImpact amount={donationAmount} />
    </>
  )
}

// New PaymentButton component
const PaymentButton = ({
  icon,
  label,
  primary,
  warning,
  isLoading,
  onClick,
}) => (
  <button
    type="button"
    onClick={onClick}
    disabled={isLoading}
    className={`
      w-full py-3 px-4 rounded-lg font-medium flex items-center justify-center space-x-2 transition duration-300
      ${primary ? 'bg-blue-500 hover:bg-blue-600 text-white' : ''}
      ${warning ? 'bg-yellow-500 hover:bg-yellow-600 text-white' : ''}
      ${
        !primary && !warning
          ? 'bg-gray-100 hover:bg-gray-200 text-gray-800'
          : ''
      }
      ${isLoading ? 'opacity-75 cursor-not-allowed' : ''}
    `}
  >
    {isLoading ? (
      <svg
        className="animate-spin h-5 w-5 text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    ) : (
      <>
        <i className={`${icon} text-lg`}></i>
        <span>{label}</span>
      </>
    )}
  </button>
)

const DonationImpact = ({ amount }) => {
  const getImpact = (value) => {
    if (value >= 150) return "Support a student's full semester"
    if (value >= 75) return 'Provide monthly mentorship sessions'
    if (value >= 55) return 'Supply educational materials'
    return 'Help with basic resources'
  }

  return amount ? (
    <div className="mt-4 p-6 bg-gradient-to-r from-blue-50 to-blue-100 rounded-xl animate-fade-in">
      <h4 className="text-lg font-semibold text-blue-800 mb-2">Your Impact</h4>
      <div className="flex items-center space-x-4">
        <div className="p-3 bg-blue-500 rounded-full">
          <i className="fas fa-heart text-white text-xl"></i>
        </div>
        <p className="text-blue-700">{getImpact(amount)}</p>
      </div>
    </div>
  ) : null
}

export default Donations
