// Import necessary dependencies
import moment from 'moment'
import React, { useState, useCallback } from 'react'
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import BreadCrumb from '../../BreadCrumb'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import { FaCalendar, FaTimes, FaPlus } from 'react-icons/fa'

// Current timestamp reference
const now = new Date()

// Sample events data
// Each event has: id, title, start date, end date, and optional description
const eventsList = [
  {
    id: 0,
    title: 'All Day Event very long title',
    allDay: true,
    start: new Date(2015, 3, 0),
    end: new Date(2015, 3, 1),
  },
  {
    id: 1,
    title: 'Long Event',
    start: new Date(2015, 3, 7),
    end: new Date(2015, 3, 10),
  },

  {
    id: 2,
    title: 'DTS STARTS',
    start: new Date(2016, 2, 13, 0, 0, 0),
    end: new Date(2016, 2, 20, 0, 0, 0),
  },

  {
    id: 3,
    title: 'DTS ENDS',
    start: new Date(2016, 10, 6, 0, 0, 0),
    end: new Date(2016, 10, 13, 0, 0, 0),
  },

  {
    id: 4,
    title: 'Some Event',
    start: new Date(2015, 3, 9, 0, 0, 0),
    end: new Date(2015, 3, 10, 0, 0, 0),
  },
  {
    id: 5,
    title: 'Conference',
    start: new Date(2015, 3, 11),
    end: new Date(2015, 3, 13),
    desc: 'Big conference for important people',
  },
  {
    id: 6,
    title: 'Meeting',
    start: new Date(2015, 3, 12, 10, 30, 0, 0),
    end: new Date(2015, 3, 12, 12, 30, 0, 0),
    desc: 'Pre-meeting meeting, to prepare for the meeting',
  },
  {
    id: 7,
    title: 'Lunch',
    start: new Date(2015, 3, 12, 12, 0, 0, 0),
    end: new Date(2015, 3, 12, 13, 0, 0, 0),
    desc: 'Power lunch',
  },
  {
    id: 8,
    title: 'Meeting',
    start: new Date(2015, 3, 12, 14, 0, 0, 0),
    end: new Date(2015, 3, 12, 15, 0, 0, 0),
  },
  {
    id: 9,
    title: 'Happy Hour',
    start: new Date(2015, 3, 12, 17, 0, 0, 0),
    end: new Date(2015, 3, 12, 17, 30, 0, 0),
    desc: 'Most important meal of the day',
  },
  {
    id: 10,
    title: 'Dinner',
    start: new Date(2015, 3, 12, 20, 0, 0, 0),
    end: new Date(2015, 3, 12, 21, 0, 0, 0),
  },
  {
    id: 11,
    title: 'Planning Meeting with Paige',
    start: new Date(2015, 3, 13, 8, 0, 0),
    end: new Date(2015, 3, 13, 10, 30, 0),
  },
  {
    id: 11.1,
    title: 'Inconvenient Conference Call',
    start: new Date(2015, 3, 13, 9, 30, 0),
    end: new Date(2015, 3, 13, 12, 0, 0),
  },
  {
    id: 11.2,
    title: "Project Kickoff - Lou's Shoes",
    start: new Date(2015, 3, 13, 11, 30, 0),
    end: new Date(2015, 3, 13, 14, 0, 0),
  },
  {
    id: 11.3,
    title: 'Quote Follow-up - Tea by Tina',
    start: new Date(2015, 3, 13, 15, 30, 0),
    end: new Date(2015, 3, 13, 16, 0, 0),
  },
  {
    id: 12,
    title: 'Late Night Event',
    start: new Date(2015, 3, 17, 19, 30, 0),
    end: new Date(2015, 3, 18, 2, 0, 0),
  },
  {
    id: 12.5,
    title: 'Late Same Night Event',
    start: new Date(2015, 3, 17, 19, 30, 0),
    end: new Date(2015, 3, 17, 23, 30, 0),
  },
  {
    id: 13,
    title: 'Multi-day Event',
    start: new Date(2015, 3, 20, 19, 30, 0),
    end: new Date(2015, 3, 22, 2, 0, 0),
  },
  {
    id: 14,
    title: 'Today',
    start: new Date(new Date().setHours(new Date().getHours() - 3)),
    end: new Date(new Date().setHours(new Date().getHours() + 3)),
  },
  {
    id: 15,
    title: 'Point in Time Event',
    start: now,
    end: now,
  },
  {
    id: 16,
    title: 'Video Record',
    start: new Date(2015, 3, 14, 15, 30, 0),
    end: new Date(2015, 3, 14, 19, 0, 0),
  },
  {
    id: 17,
    title: 'Dutch Song Producing',
    start: new Date(2015, 3, 14, 16, 30, 0),
    end: new Date(2015, 3, 14, 20, 0, 0),
  },
  {
    id: 18,
    title: 'Itaewon Meeting',
    start: new Date(2015, 3, 14, 16, 30, 0),
    end: new Date(2015, 3, 14, 17, 30, 0),
  },
  {
    id: 19,
    title: 'Online Coding Test',
    start: new Date(2015, 3, 14, 17, 30, 0),
    end: new Date(2015, 3, 14, 20, 30, 0),
  },
  {
    id: 20,
    title: 'An overlapped Event',
    start: new Date(2015, 3, 14, 17, 0, 0),
    end: new Date(2015, 3, 14, 18, 30, 0),
  },
  {
    id: 21,
    title: 'Phone Interview',
    start: new Date(2015, 3, 14, 17, 0, 0),
    end: new Date(2015, 3, 14, 18, 30, 0),
  },
  {
    id: 22,
    title: 'Cooking Class',
    start: new Date(2015, 3, 14, 17, 30, 0),
    end: new Date(2015, 3, 14, 19, 0, 0),
  },
  {
    id: 23,
    title: 'Go to the gym',
    start: new Date(2015, 3, 14, 18, 30, 0),
    end: new Date(2015, 3, 14, 20, 0, 0),
  },
  {
    id: 24,
    title: 'DST ends on this day (Europe)',
    start: new Date(2022, 9, 30, 0, 0, 0),
    end: new Date(2022, 9, 30, 4, 30, 0),
  },
  {
    id: 25,
    title: 'DST ends on this day (America)',
    start: new Date(2022, 10, 6, 0, 0, 0),
    end: new Date(2022, 10, 6, 4, 30, 0),
  },
  {
    id: 26,
    title: 'DST starts on this day (America)',
    start: new Date(2023, 2, 12, 0, 0, 0),
    end: new Date(2023, 2, 12, 4, 30, 0),
  },
  {
    id: 27,
    title: 'DST starts on this day (Europe)',
    start: new Date(2023, 2, 26, 0, 0, 0),
    end: new Date(2023, 2, 26, 4, 30, 0),
  },
]

const Schedule = function () {
  // State management
  const [selectedEvent, setSelectedEvent] = useState(null) // For viewing event details
  const [showNewEventModal, setShowNewEventModal] = useState(false) // Controls new event modal
  const [newEvent, setNewEvent] = useState({
    title: '',
    start: null,
    end: null,
    desc: '',
  })
  const [view, setView] = useState(Views.MONTH) // Calendar view type
  const [events, setEvents] = useState(eventsList) // All calendar events

  // Event handlers
  const handleSelectEvent = useCallback((event) => {
    setSelectedEvent(event) // Show event details when an event is clicked
  }, [])

  const handleSelectSlot = useCallback(({ start, end }) => {
    // Open new event modal when a calendar slot is selected
    setNewEvent({
      title: '',
      start: new Date(start),
      end: new Date(end),
      desc: '',
    })
    setShowNewEventModal(true)
  }, [])

  const handleCreateEvent = () => {
    // Add new event to calendar if title exists
    if (newEvent.title) {
      setEvents((prev) => [...prev, { ...newEvent, id: Date.now() }])
      setShowNewEventModal(false)
      setNewEvent({ title: '', start: null, end: null, desc: '' })
    }
  }

  return (
    <main className="w-full p-4 md:p-8">
      {/* Breadcrumb navigation */}
      <BreadCrumb
        key={Math.random() * 1000}
        title={'My Schedule'}
        slugs={[
          {
            display: 'Dashboard',
            to: '/dashboard/home',
            active: false,
          },
          {
            display: 'Schedule',
            to: '/dashboard/schedule',
            active: true,
          },
        ]}
      />

      <section className="w-full mt-4">
        {/* Calendar header with title and Add Event button */}
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-2">
            <FaCalendar className="text-primary-600 text-xl" />
            <h2 className="text-2xl font-semibold text-gray-800">Calendar</h2>
          </div>
          <button
            onClick={() => setShowNewEventModal(true)}
            className="flex items-center gap-2 bg-primary-600 text-white px-4 py-2 rounded-lg hover:bg-primary-700 transition-colors"
          >
            <FaPlus /> Add Event
          </button>
        </div>

        {/* Main calendar component */}
        <div className="p-8 mt-4 rounded-lg shadow-[0_0_10px_rgba(1,41,112,0.1)] bg-white overflow-auto">
          <Calendar
            localizer={momentLocalizer(moment)}
            events={events}
            startAccessor="start"
            endAccessor="end"
            className="min-h-[600px]"
            onSelectEvent={handleSelectEvent}
            onSelectSlot={handleSelectSlot}
            selectable
            view={view}
            onView={setView}
            views={['month', 'week', 'day', 'agenda']}
            tooltipAccessor={(event) => event.desc || event.title}
            eventPropGetter={(event) => ({
              style: {
                backgroundColor: '#4F46E5',
                borderRadius: '4px',
              },
            })}
          />
        </div>
      </section>

      {/* Modal for viewing event details */}
      {selectedEvent && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full relative">
            <button
              onClick={() => setSelectedEvent(null)}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
            >
              <FaTimes />
            </button>
            <h3 className="text-xl font-semibold mb-2">
              {selectedEvent.title}
            </h3>
            <p className="text-gray-600 mb-4">{selectedEvent.desc}</p>
            <div className="text-sm text-gray-500">
              <p>
                Start:{' '}
                {moment(selectedEvent.start).format('MMMM D, YYYY h:mm A')}
              </p>
              <p>
                End: {moment(selectedEvent.end).format('MMMM D, YYYY h:mm A')}
              </p>
            </div>
            <div className="flex gap-2 mt-4">
              <button
                onClick={() => setSelectedEvent(null)}
                className="flex-1 px-4 py-2 border border-gray-300 text-gray-700 rounded hover:bg-gray-50"
              >
                Close
              </button>
              <button
                onClick={() => {
                  setEvents((prev) =>
                    prev.filter((e) => e.id !== selectedEvent.id)
                  )
                  setSelectedEvent(null)
                }}
                className="flex-1 px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modal for creating new events */}
      {showNewEventModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <h3 className="text-xl font-semibold mb-4">Create New Event</h3>

            {/* Title Input */}
            <input
              type="text"
              placeholder="Event Title"
              className="w-full p-2 border rounded mb-4"
              value={newEvent.title}
              onChange={(e) =>
                setNewEvent((prev) => ({ ...prev, title: e.target.value }))
              }
            />

            {/* Date and Time Selection */}
            <div className="grid grid-cols-2 gap-4 mb-4">
              {/* Start Date & Time */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Start Date & Time
                </label>
                <input
                  type="datetime-local"
                  className="w-full p-2 border rounded"
                  value={
                    newEvent.start
                      ? moment(newEvent.start).format('YYYY-MM-DDTHH:mm')
                      : ''
                  }
                  onChange={(e) =>
                    setNewEvent((prev) => ({
                      ...prev,
                      start: new Date(e.target.value),
                    }))
                  }
                />
              </div>

              {/* End Date & Time */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  End Date & Time
                </label>
                <input
                  type="datetime-local"
                  className="w-full p-2 border rounded"
                  value={
                    newEvent.end
                      ? moment(newEvent.end).format('YYYY-MM-DDTHH:mm')
                      : ''
                  }
                  onChange={(e) =>
                    setNewEvent((prev) => ({
                      ...prev,
                      end: new Date(e.target.value),
                    }))
                  }
                />
              </div>
            </div>

            {/* Description */}
            <textarea
              placeholder="Description"
              className="w-full p-2 border rounded mb-4"
              value={newEvent.desc}
              onChange={(e) =>
                setNewEvent((prev) => ({ ...prev, desc: e.target.value }))
              }
            />

            {/* Action Buttons */}
            <div className="flex gap-2">
              <button
                onClick={() => setShowNewEventModal(false)}
                className="flex-1 px-4 py-2 border border-gray-300 text-gray-700 rounded hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleCreateEvent}
                className="flex-1 px-4 py-2 bg-primary-600 text-white rounded hover:bg-primary-700"
              >
                Create
              </button>
            </div>
          </div>
        </div>
      )}
    </main>
  )
}

export default Schedule
