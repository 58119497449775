import React from 'react'
import * as BoostrapIcon from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
import Banner from '../../../components/Banner'
import Footer from '../../../components/Footer'
import './index.scss'

// Import images
import AnushkaImage from '../../../assets/images/people/Anushka.jpeg'
import FillerImage from '../../../assets/images/people/filler.png'
import GlenImage from '../../../assets/images/people/glen.png'
import KinloImage from '../../../assets/images/people/ket.jpeg'
import WaltersImage from '../../../assets/images/people/KumoWalters.jpeg'
import RonaldImage from '../../../assets/images/people/Ronald.jpg'
import TasangImage from '../../../assets/images/people/TasangLesley.jpeg'
import VanessaImage from '../../../assets/images/people/VanessaLogan.jpg'
import WinnieImage from '../../../assets/images/people/Winnie-Vallonia.jpeg'
//import KETImage from '../../../assets/images/people/KET.jpg'
import AlphonesImage from '../../../assets/images/people/Alphones2.jpg'
import ClaudeImage from '../../../assets/images/people/Claude.jpg'
import KelisonImage from '../../../assets/images/people/Kelison.jpg'
import LucienImage from '../../../assets/images/people/Lucien.jpg'
import OdiliaImage from '../../../assets/images/people/Odilia.jpg'
import VitalisImage from '../../../assets/images/people/Vitalis.jpg'
import AlainImage from '../../../assets/images/people/Alain111.png'
import EnduranceImage from '../../../assets/images/people/Endurance.jpg'
import AminkengImage from '../../../assets/images/people/Aminkeng.jpg'
import LouiseImage from '../../../assets/images/people/Louise.jpg'
import AlbertImage from '../../../assets/images/people/Albert.jpg'
import SophieImage from '../../../assets/images/people/Sophie.jpg'
import WilhelmImage from '../../../assets/images/people/Wilhelm.jpg'
import LornaImage from '../../../assets/images/people/Lorna.jpg'
import DinImage from '../../../assets/images/people/Din.jpg'
import Jonathan from '../../../assets/images/people/Jonathan.jpg'
import Canisia from '../../../assets/images/people/Canisia.jpg'

// Main Team component
const Team = () => {
  // Core team members data
  const coreTeamMembers = [
    {
      name: 'Kinlo Ephraim T.',
      title: 'Founder',
      education: 'Constructor University',
      image: KinloImage,
    },
    {
      name: 'Kamaha Ronald P.',
      title: 'Operations',
      education: 'USIU',
      image: RonaldImage,
    },
    {
      name: 'Niassan K. Anushka',
      title: 'Communications',
      education: 'AUB',
      image: AnushkaImage,
    },
    {
      name: 'Kumo Walters',
      title: 'Finances',
      education: 'Masters in Economics',
      image: WaltersImage,
    },
    {
      name: 'Glen Y. Nfor',
      title: 'Technical',
      education: 'Princeton University',
      image: GlenImage,
    },
  ]

  // Ambassadors data
  const ambassadors = [
    {
      name: 'Vanessa Logan A.',
      education: 'Ashesi',
      title: 'HOD, ICT, Finance',
      departments: ['ICT', 'Chem'],
      image: VanessaImage,
    },
    {
      name: 'Alphonse Nyuikonghe',
      education: 'ENSTP Yaounde',
      title: 'HOD, Maths',
      departments: ['Maths', 'Physics'],
      image: AlphonesImage,
    },
    {
      name: 'Nfor Glen Y.',
      education: 'Princeton University',
      title: 'HOD Computer Science',
      departments: ['CSC', 'Math', 'Phy'],
      image: GlenImage,
    },
    {
      name: 'Nna Winnie Vallonia',
      education: 'Northwestern University',
      title: 'Media Manager',
      departments: ['Chem', 'Counselling'],
      image: WinnieImage,
    },
    {
      name: 'Tasang Lesley N.',
      education: 'NASPW Yaounde',
      title: 'HOD Physics',
      departments: ['Phy', 'Math'],
      image: TasangImage,
    },
    {
      name: 'Tonlieu N. Alain Jorel',
      education: 'Open Dreams',
      title: 'Ambassador',
      departments: ['Finance', 'grands & funding'],
      image: AlainImage,
    },
    {
      name: 'Kenyaupli Lucien',
      education: 'Biomedical Eng',
      title: 'Ambassador',
      departments: ['Social Media', 'Counseling'],
      image: LucienImage,
    },
    {
      name: 'Claude Tomoh',
      education: 'Ashesi University/BSC',
      title: 'Ambassador',
      departments: ['Media & Communication'],
      image: ClaudeImage,
    },
    {
      name: 'TATAH ODILIA',
      education: 'Paramedical student',
      title: 'Ambassador',
      departments: ['Chemistry', 'Bio'],
      image: OdiliaImage,
    },
    {
      name: 'Tifu Kelison',
      education: 'University CS',
      title: 'Brand Designer',
      departments: ['Media & Design'],
      image: KelisonImage,
    },
    {
      name: 'Ndintawel Sophie Te-e',
      education: 'FET Buea',
      title: 'Ambassador',
      departments: ['Media', 'Communication'],
      image: SophieImage,
    },
    {
      name: 'Amomoh Jonathan',
      education: 'Open Dreams',
      title: "Math Dep't",
      departments: ['Math', 'ICT'],
      image: FillerImage,
    },
    {
      name: 'Ngam Vitalis Yuh ',
      education: 'SWE student',
      title: "Math Dep't",
      departments: ['CS'],
      image: VitalisImage,
    },
    {
      name: 'Bongnwi Promise',
      education: 'Medical Student',
      title: 'Ambassador',
      departments: ['Maths & Physics'],
      image: FillerImage,
    },
    {
      name: 'SHEY ENDURANCE',
      education: 'University student',
      title: 'Ambassador',
      departments: ['Social media assistant'],
      image: EnduranceImage,
    },
    {
      name: 'AMINKENG AZITABONG',
      education: 'University student',
      title: 'Ambassador',
      departments: ['Maths & Physics'],
      image: AminkengImage,
    },
    {
      name: 'FUL LOUIS KESUH',
      education: 'Medical student',
      title: 'Ambassador',
      departments: ['Bio and Chem'],
      image: LouiseImage,
    },
    {
      name: 'Nkimih Albert',
      education: 'CS at NAHPI',
      title: 'Ambassador',
      departments: ['Computer Science & ICT'],
      image: AlbertImage,
    },
    {
      name: 'Tchinda Wilhelm',
      education: 'Northwestern Polytec',
      title: 'Ambassador',
      departments: ['CS & Physics'],
      image: WilhelmImage,
    },
    {
      name: 'Lorna-Lois Nyoh',
      education: 'Open Dreams',
      title: 'Ambassador',
      departments: ['Social Media'],
      image: LornaImage,
    },
    {
      name: 'Dinnyuy Lemnyuy',
      education: 'Masters in Mechanical Eng',
      title: 'Ambassador',
      departments: ['Physics and Maths'],
      image: DinImage,
    },
    {
      name: 'Amomoh Jonathan',
      education: 'Open Dreams',
      title: 'Ambassador',
      departments: ['Physics and Maths'],
      image: Jonathan,
    },
    {
      name: 'Zifac Canisia Nkerifac',
      education: 'BSc in transport & logistics',
      title: 'Ambassador',
      departments: ['Physics & Maths'],
      image: Canisia,
    },
  ]

  return (
    <>
      <Banner
        title="The KET Academy Team"
        content="We are a group of visionary, selfless individuals dedicated to transforming the education system in Cameroon. Our mission is to simplify academics for students across the nation, fostering development and positive change."
      />

      <section className="py-12 px-2 sm:px-4 lg:px-6">
        <div className="max-w-7xl mx-auto">
          <div className="mb-16">
            <h2 className="text-3xl font-bold text-center text-primary mb-8">
              Core Team
            </h2>
            <div className="flex flex-wrap justify-center gap-4">
              {coreTeamMembers.map((member, index) => (
                <CoreTeamCard key={index} {...member} />
              ))}
            </div>
          </div>

          <div className="mb-16">
            <h2 className="text-3xl font-bold text-center text-primary mb-8">
              Our Ambassadors
            </h2>
            <div className="flex flex-wrap justify-center gap-4">
              {ambassadors.map((member, index) => (
                <MemberCard key={index} {...member} />
              ))}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}

// CoreTeamCard component for core team members
const CoreTeamCard = ({ name, title, education, image }) => (
  <div className="member">
    <div className="container">
      <div className="avatar mx-2">
        <img
          src={image}
          alt={`${name} - Core team member`}
          className="rounded-full"
        />
      </div>
      <div className="info mx-2">
        <h4 className="text-primary font-bold mb-1">{name}</h4>
        <p>{education}</p>
        <hr className="w-16 my-2 h-1 bg-accent-alt outline-none border-none"></hr>
        <span className="text-primary my-2">{title}</span>
        <div className="contacts">
          <Link to="https://www.twitter.com/home" className="twitter">
            <BoostrapIcon.Twitter className="text-2xl text-primary" />
          </Link>
          <Link
            to="https://www.facebook.com/kinlo.ephraim/"
            className="facebook"
          >
            <BoostrapIcon.Facebook className="text-2xl text-primary" />
          </Link>
          <Link
            to="https://www.instagram.com/kinloephraim/"
            className="instagram"
          >
            <BoostrapIcon.Instagram className="text-2xl text-primary" />
          </Link>
          <Link
            to="https://www.linkedin.com/in/kinlo-ephraim-tangiri-a70113218/"
            className="linkedin"
          >
            <BoostrapIcon.Linkedin className="text-2xl text-primary" />
          </Link>
        </div>
      </div>
    </div>
  </div>
)

// MemberCard component for ambassadors
const MemberCard = ({ name, education, title, departments, image }) => (
  <div className="member">
    <div className="container">
      <div className="avatar mx-2 w-32 h-32 overflow-hidden">
        {' '}
        {/* Container with constant size */}
        <img
          src={image}
          alt={`${name} - Ambassador`}
          className="rounded-full object-cover w-full h-full" // Ensures the image is contained within the square without stretching
        />
      </div>
      <div className="info mx-2">
        <h4 className="text-primary font-bold mb-1 text-md">{name}</h4>
        <p>{education}</p>
        <hr className="w-16 my-2 h-1 bg-accent-alt outline-none border-none"></hr>
        <span className="text-primary my-2">{title}</span>
        <div className="depts my-2 mx-auto">
          {departments.map((dept, index) => (
            <span
              key={index}
              className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-4 py-1 rounded cursor-pointer"
            >
              {dept}
            </span>
          ))}
        </div>
        <div className="contacts">
          <Link to="https://www.twitter.com/home" className="twitter">
            <BoostrapIcon.Twitter className="text-2xl text-primary" />
          </Link>
          <Link
            to="https://www.facebook.com/kinlo.ephraim/"
            className="facebook"
          >
            <BoostrapIcon.Facebook className="text-2xl text-primary" />
          </Link>
          <Link
            to="https://www.instagram.com/kinloephraim/"
            className="instagram"
          >
            <BoostrapIcon.Instagram className="text-2xl text-primary" />
          </Link>
          <Link
            to="https://www.linkedin.com/in/kinlo-ephraim-tangiri-a70113218/"
            className="linkedin"
          >
            <BoostrapIcon.Linkedin className="text-2xl text-primary" />
          </Link>
        </div>
      </div>
    </div>
  </div>
)

export default Team
