import React, { useState } from 'react'
import BreadCrumb from '../../BreadCrumb'
import InstructorCard from './InstructorCard'
import SearchBar from './SearchBar'
import { DUMMY_PROFILE_IMAGE } from '../../../utils/constants'

const Instructors = function () {
  const [searchTerm, setSearchTerm] = useState('')
  const [instructors] = useState([
    {
      name: 'Dr. Sarah Johnson',
      role: 'Computer Science Lead',
      specialization: 'AI & Machine Learning',
      rating: 4.9,
      students: 12500,
      courses: 8,
      image: DUMMY_PROFILE_IMAGE,
      social: {
        linkedin: '#',
        twitter: '#',
        github: '#',
      },
    },
    {
      name: 'Vanessa Logan A.',
      role: 'ICT & Finance Lead',
      specialization: 'Information Technology',
      rating: 4.8,
      students: 8200,
      courses: 6,
      image: DUMMY_PROFILE_IMAGE,
      social: {
        linkedin: '#',
        twitter: '#',
        github: '#',
      },
    },
    {
      name: 'Alphonse Nyuikonghe',
      role: 'Mathematics Department Head',
      specialization: 'Advanced Mathematics',
      rating: 4.9,
      students: 10300,
      courses: 12,
      image: DUMMY_PROFILE_IMAGE,
      social: {
        linkedin: '#',
        twitter: '#',
        github: '#',
      },
    },
    {
      name: 'Nfor Glen Y.',
      role: 'Computer Science HOD',
      specialization: 'Software Engineering',
      rating: 4.7,
      students: 9500,
      courses: 7,
      image: DUMMY_PROFILE_IMAGE,
      social: {
        linkedin: '#',
        twitter: '#',
        github: '#',
      },
    },
    {
      name: 'Tasang Lesley N.',
      role: 'Physics Department Head',
      specialization: 'Theoretical Physics',
      rating: 4.8,
      students: 7800,
      courses: 9,
      image: DUMMY_PROFILE_IMAGE,
      social: {
        linkedin: '#',
        twitter: '#',
        github: '#',
      },
    },
    {
      name: 'Claude Tomoh',
      role: 'Media & Communications',
      specialization: 'Digital Communications',
      rating: 4.6,
      students: 5600,
      courses: 4,
      image: DUMMY_PROFILE_IMAGE,
      social: {
        linkedin: '#',
        twitter: '#',
        github: '#',
      },
    },
    {
      name: 'Tatah Odilia',
      role: 'Science Instructor',
      specialization: 'Chemistry & Biology',
      rating: 4.7,
      students: 6900,
      courses: 5,
      image: DUMMY_PROFILE_IMAGE,
      social: {
        linkedin: '#',
        twitter: '#',
        github: '#',
      },
    },
    {
      name: 'Dinnyuy Lemnyuy',
      role: 'Engineering Mentor',
      specialization: 'Mechanical Engineering',
      rating: 4.8,
      students: 4200,
      courses: 6,
      image: DUMMY_PROFILE_IMAGE,
      social: {
        linkedin: '#',
        twitter: '#',
        github: '#',
      },
    },
    {
      name: 'Nkimih Albert',
      role: 'Technology Instructor',
      specialization: 'Computer Science & ICT',
      rating: 4.6,
      students: 5800,
      courses: 5,
      image: DUMMY_PROFILE_IMAGE,
      social: {
        linkedin: '#',
        twitter: '#',
        github: '#',
      },
    },
    {
      name: 'Ful Louis Kesuh',
      role: 'Science Counselor',
      specialization: 'Biology & Chemistry',
      rating: 4.7,
      students: 3900,
      courses: 4,
      image: DUMMY_PROFILE_IMAGE,
      social: {
        linkedin: '#',
        twitter: '#',
        github: '#',
      },
    },
  ])

  const handleSearch = (term) => {
    setSearchTerm(term)
  }

  const handleFilter = () => {
    // Implement filter functionality
  }

  const filteredInstructors = instructors.filter((instructor) =>
    instructor.name.toLowerCase().includes(searchTerm.toLowerCase())
  )

  return (
    <main className="w-full p-4 md:p-8">
      <BreadCrumb
        title="Instructors"
        slugs={[
          { display: 'Dashboard', to: '/dashboard/home', active: false },
          {
            display: 'Instructors',
            to: '/dashboard/instructors',
            active: true,
          },
        ]}
      />

      <section className="mt-8">
        <SearchBar onSearch={handleSearch} onFilter={handleFilter} />

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredInstructors.map((instructor, index) => (
            <InstructorCard key={index} instructor={instructor} />
          ))}
        </div>
      </section>
    </main>
  )
}

export default Instructors
