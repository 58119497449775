import React from 'react'
import Banner from '../../../components/Banner'
import Footer from '../../../components/Footer'

const Privacy = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <Banner
        title="Privacy Policy"
        content="We value your privacy and are committed to protecting your personal information."
      />

      <div className="container mx-auto px-4 py-12">
        <div className="bg-white rounded-lg shadow-sm p-8 space-y-8">
          <section>
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              1. Information We Collect
            </h2>
            <p className="text-gray-600 leading-relaxed mb-4">
              To provide you with the best possible learning experience, we
              collect:
            </p>
            <ul className="list-disc pl-6 text-gray-600 space-y-2">
              <li>Personal information (name, email, contact details)</li>
              <li>Educational background and learning preferences</li>
              <li>Course progress and performance data</li>
              <li>AI interaction data to improve our learning tools</li>
              <li>Device and browser information for platform optimization</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              2. How We Use Your Information
            </h2>
            <p className="text-gray-600 leading-relaxed mb-4">
              Your information helps us:
            </p>
            <ul className="list-disc pl-6 text-gray-600 space-y-2">
              <li>Personalize your learning experience</li>
              <li>Improve our AI-powered educational tools</li>
              <li>Track and analyze learning outcomes</li>
              <li>Communicate important updates and recommendations</li>
              <li>Ensure platform security and prevent misuse</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              3. AI Data Processing
            </h2>
            <p className="text-gray-600 leading-relaxed mb-4">
              Our AI-powered features process data to enhance your learning
              experience:
            </p>
            <ul className="list-disc pl-6 text-gray-600 space-y-2">
              <li>
                Learning patterns analysis for personalized recommendations
              </li>
              <li>Chat interactions for improved AI responses</li>
              <li>Performance data to adapt content difficulty</li>
              <li>Anonymous usage patterns to enhance AI algorithms</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              4. Data Protection
            </h2>
            <p className="text-gray-600 leading-relaxed">
              We implement robust security measures to protect your information:
            </p>
            <ul className="list-disc pl-6 text-gray-600 space-y-2 mt-4">
              <li>Encrypted data transmission and storage</li>
              <li>Regular security audits and updates</li>
              <li>Strict access controls for staff</li>
              <li>Compliance with international data protection standards</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              5. Your Rights
            </h2>
            <p className="text-gray-600 leading-relaxed mb-4">
              You have the right to:
            </p>
            <ul className="list-disc pl-6 text-gray-600 space-y-2">
              <li>Access your personal data</li>
              <li>Request data correction or deletion</li>
              <li>Opt-out of non-essential data collection</li>
              <li>Export your learning data</li>
              <li>Withdraw consent for data processing</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              6. Third-Party Services
            </h2>
            <p className="text-gray-600 leading-relaxed">
              We may use trusted third-party services for:
            </p>
            <ul className="list-disc pl-6 text-gray-600 space-y-2 mt-4">
              <li>Payment processing</li>
              <li>Analytics and performance monitoring</li>
              <li>AI and machine learning services</li>
              <li>Content delivery and hosting</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              7. Contact Us
            </h2>
            <p className="text-gray-600 leading-relaxed">
              For privacy-related inquiries or to exercise your rights, contact
              our Data Protection Officer at ketacademy1@gmail.com or through
              our support channels.
            </p>
          </section>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default Privacy
